import styled from 'styled-components';

const MainComp: React.FC<X> = ({ className, children }) => {
	return <main className={className}>{children}</main>;
};

const Main = styled(MainComp)`
	width: 100vw;
	height: 100vh; // (page - header - footer)
	background: rgb(91, 91, 91);
	background: linear-gradient(162deg, #e0dfdf 0%, #d3d3d3 95%);

	// mobile adjustments
	@media screen and (max-width: 480px) {
		height: unset;
		width: 100%;
	}
`;

export default Main;
