
/*

QRL controller implementation

ALL FUNCTIONS / VARIABLES THAT USE THESE EXPORTS MUST BE ASYNC / AWAIT TO AVOID PROMISES

usage {
    import { importedMethodName } from './controllers'

    const fetchRequest = async () => {
        const response = await importedMethodName(params);
        -- do something with the data
    }
}

*/

type Query = ICustomElement<string, string | number>;

const isString = (args: number): boolean => {
    if (isNaN(args)) { return true; }
    else { return false; };
}

type Header = {
    [name: string]: string
}

type RequestOptions = {
    method: string,
    headers: Header,
    body: string,
}

export const QRLController  = {
    /*
        takes blobName, serverside search and res regex match;

        usage: inside search bar and immediate call when QRL renders from fabtech with their selected robot params;
    */
    async getBlobsByName(blobName: string) {
        try {
            const res = await fetch(`http://localhost:8080/blob/${blobName}`);
            // const res = await fetch(`http://localhost:3001/blob/${blobName}`)
            const data = await res.json();
            return data.blobs;
        } catch (err) {
            console.error({ message: `failed to retrieve blobs`, error: err });
        };
    },
    /*
        takes start number and limiter, can be counted / realized inside component, turns number to string and queries blobs;
        
        usage: update start and limit numbers as the user scrolls further down the page, rendering more content;
    */
    async getBlobs(start: number, limit: number) {
        let data;
        start.toString();
        limit.toString();
        try {
            const res = await fetch(`http://localhost:8080/blob/?start=${start}&limit=${limit}`);
            data = await res.json();
            return data.blobs;
        } catch (err) {
            console.error({ message: `failed to retrieve blobs`, error: err });
        };
        return data;
    },
    /*
        takes blob ID, returns single blob data

        usage: used when the user selects a blob interactable (eg: notes) to render specific blob data
    */
    async getBlobById(blobId: string) {
        let data;
        try {
            const res = await fetch(`http://localhost:8080/blob/single/${blobId}`);
            data = await res.json();
            return data.blob;
        } catch (err) {
            console.error({ message: `failed to retrieve blob`, error: err });
        };
        return data;
    },
    /*
        takes user ID and blob ID to update the respective models
        takes the created note text as a string

        usage: when the user submits a note this function calls and adds the note to their profile and respective blob
    */
    async createNote(userId: string, blobId: string, bodyText: string) {
        const body = {
            bodyText: bodyText
        }
        const headers: Header = {
            'Content-Type': 'application/json'
        };
        const reqOptions: RequestOptions =  {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body)
        }
        try {
            const res = await fetch(`http://localhost:8080/note/${userId}/${blobId}`,
                reqOptions
            );
            return await res.json();
        } catch (err) {
            console.error({ message: `failed to create note`, error: err });
        };
    },
    /*
    returns quiz results for fabtech

    usage: used for rendering quiz results in admin page
    */
    async getResults() {
        try {
            const res = await fetch(`http://localhost:3001/analytics`);
            const data = await res.json();
            return data.results;
        } catch (err) {
            console.error({ message: `failed to retrieve blob`, error: err });
        };
    },
    /*
    saves a blob to a user profile

    usage: fired when the user selects the bookmark button on a blob
    */
   async saveBlobToUser(userId: string, blobId: string) {
        try {
            const res = await fetch(`http://localhost:8080/user/${userId}/${blobId}`,
            {
                method: 'PUT'
            });
            return await res.json();
        } catch (err) {
            console.error({ message: `failed to retrieve blob`, error: err });
        };
   },
   /*
   query current logged in user

   usage: get detailed user info for profile rendering on other timeline checks
   */
   async getLoggedInUser(userId: string) {
    try {
        const res = await fetch(`http://localhost:8080/user/${userId}`)
        // const res = await fetch(`http://localhost:3001/user/${userId}`)
        const data = await res.json();
        return data.user;
    } catch (err) {
        console.error({ message: `failed to retrieve blob`, error: err });
    };
   }
}

const { getBlobById, getBlobs, getBlobsByName, createNote, saveBlobToUser, getLoggedInUser, getResults } = QRLController;

export { getBlobById, getBlobs, getBlobsByName, createNote, saveBlobToUser, getLoggedInUser, getResults };