
import { Route, Routes } from 'react-router-dom';
import AdminHome from './Home/page'
import AdminManageUsers from './ManageUsers/page'

const AdminRouter = () => {
    return (
        <Routes>
            <Route
                path="/"
                element={<AdminHome />}
            />
            <Route
                path="/users"
                element={<AdminManageUsers />}
            />
        </Routes>
    )
}

export default AdminRouter;