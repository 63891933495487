import { ReactNode } from 'react';
import styled from 'styled-components';

interface ButtonProps {
	className?: string;
	type: 'fill' | 'pulse' | 'close' | 'raise' | 'slide' | 'up' | 'offset';
	color: string;
	children: ReactNode;
	link?: string;
	onClick?: any;
}

const Button: React.FC<ButtonProps> = ({ className, type, children, link, onClick }) => {
	return (
		<div className={className}>
			{link ? (
				<a href={link}>
					<button className={type}>{children}</button>
				</a>
			) : (
				<button
					className={type}
					onClick={onClick}
				>
					{children}
				</button>
			)}
		</div>
	);
};

export default styled(Button)`
	& {
		--color: ${(props) => props.color};
		--hover: ${(props) => props.color};
	}

	.fill:hover,
	.fill:focus {
		box-shadow: inset 0 0 0 2em var(--hover);
	}

	/* Animate the size, outside */
	.pulse:active {
		animation: pulse 1s;
		box-shadow: 0 0 0 0.8em transparent;
	}

	@keyframes pulse {
		0% {
			box-shadow: 0 0 0 0 var(--hover);
		}
	}

	/* Stack multiple shadows, one from the left, the other from the right */
	.close:hover,
	.close:focus {
		box-shadow: inset -3.5em 0 0 0 var(--hover), inset 3.5em 0 0 0 var(--hover);
	}

	/* Size can also be negative; see how it's smaller than the element */
	.raise:hover,
	.raise:focus {
		box-shadow: 0 0.5em 0.5em -0.4em var(--hover);
		transform: translateY(-0.25em);
	}

	/* Animating from the bottom */
	.up:hover,
	.up:focus {
		box-shadow: inset 0 -3.25em 0 0 var(--hover);
	}

	/* And from the left */
	.slide:hover,
	.slide:focus {
		box-shadow: inset 6.5em 0 0 0 var(--hover);
	}

	/* Multiple shadows, one on the outside, another on the inside */
	.offset {
		box-shadow: 0.3em 0.3em 0 0 var(--color), inset 0.3em 0.3em 0 0 var(--color);
	}

	.offset:hover,
	.offset:focus {
		box-shadow: 0 0 0 0 var(--hover), inset 0 0 0 0 var(--hover);
	}

	button {
		color: var(--color);
		transition: 0.25s;
		background: none;
		border: 2px solid;
		font: inherit;
		line-height: 1;
		margin: 0.5em;
		padding: 1em 2em;
		font-weight: 700;
		border-radius: 1rem;
	}

	button:active {
		border-color: var(--hover);
		color: #fff;
	}
`;
