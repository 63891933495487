import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
	// global vars
	@import url('https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
	:root {

		--primary: #3e2b2f;
		--primary-light: #cdcc98;
		--secondary-light: #F5A81C;
		--secondary: #CE202F;
		--tertiary: #cdcc98;
		
		/* --container-background: white; */

		--header-height: 5vh;
		--header-mobile-height: 18vh;
		--header-background: var(--primary);

		--footer-height: 7vh;
		--footer-background: var(--primary);

		--navbar-width: 18vw;
		--navbar-width-min: 6vw;
		--navbar-width-collapsed: 6vw;
		--navbar-width-collapsed-min: 3vw;
		--navbar-background: var(--primary-light);
		
		--breadcrumb-height: 4vh;
		--breadcrumb-background: none;
		
		--tooltip-text-color: white;
		--tooltip-background-color: black;
		--tooltip-margin: 30px;
		--tooltip-arrow-size: 6px;
	}

	body {
		font-family: 'Karla', sans-serif!important;
	
	}

	switchbox {
		display: flex;
	}

	& .spinner-border {
		width: 7vw !important;
		height: 7vw !important;
	}
	
	@media screen and (max-width: 480px) {
		switchbox {
			display: block;
		}
	}
	

	/* width */
	::-webkit-scrollbar {
		width: 10px;
		border-radius: 10px;
		transform: translateX(20px);
	}

	/* Track */
	::-webkit-scrollbar-track {
		background: #f1f1f1;
		/* margin-top: 100px;
		margin-bottom: 60px; */
		border-radius: 5px;
	}

	/* Handle */
	::-webkit-scrollbar-thumb {
		background: #888;
		border-radius: 5px;
	}

	/* Handle on hover */
	::-webkit-scrollbar-thumb:hover {
		background: #555;
	}
`;

export default GlobalStyles;
