// DEFAULT CONTENT FOR QRL -TT
// ----------------------
import styled from 'styled-components';
import PostDisplay from './components/PostDisplay';
import { HandleUser } from './handlers/HandleUser';
import folderIcon from '../../../../assets/png/Asset_1.png';
import { FaArrowAltCircleDown, FaArrowLeft } from 'react-icons/fa';
import { ReactComponent as CloudUpload } from '../../../../assets/tool_icons/Cloud_Upload_Black.svg';
import { Link } from 'react-router-dom';
import { FaDownload, FaRegEye, FaCogs, FaTrash } from 'react-icons/fa';
import { SlSpeech } from 'react-icons/sl';
import SearchIcon from '../../../../assets/png/Q-01.png';
import { useEffect, useState } from 'react';
import Notes from './components/Notes';
import AssetInfo from './components/AssetInfo';
import { FaSadTear } from 'react-icons/fa';
import { siemens } from '../../../../assets';
import Step1 from '../../../../assets/kennon_steps/Step1.jpg'

import { useParams } from 'react-router-dom';

import { FaSchool } from 'react-icons/fa';
import Quiz from '../../../../assets/png/Quiz.png';

interface QRLProps extends X {
	buildingSelected?: string;
}

type asset = {
	name: string;
	operation: string;
	projName: string;
	isFolder: boolean;
	link: string;
}

const QRLHome: React.FC<QRLProps> = ({ className, buildingSelected }) => {
	// grabbing user once at page load and then passing to components as prop
	const { user } = HandleUser();

	const [building, setBuilding] = useState("EMPTY");
	const [notesVisible, setNotesVisible] = useState("hidden")
	const [tableVisible, setTableVisible] = useState("")


	const [sharedAssets, setSharedAssets] = useState<asset[]>([]);

	const { name } = useParams();
 
	const laserTableAssets: asset[] = [
		{
			name: "Laser Table",
			operation: "Bladder Assembly",
			projName: "RAPID",
			isFolder: false,
			link: "/quiz/lasertable",
		},
		{
			name: "Laser Table",
			operation: "Bladder Cleaning",
			projName: "RAPID",
			isFolder: false,
			link: "/quiz/lasertable",
		},
		{
			name: "Laser Table",
			operation: "Bladder Removal",
			projName: "RAPID",
			isFolder: false,
			link: "/quiz/lasertable",
		},
		{
			name: "Laser Table",
			operation: "ClearWeld Sprayer Testing",
			projName: "RAPID",
			isFolder: true,
			link: "/qrl/lasertable",
		},
		{
			name: "Laser Table",
			operation: "MOLLE Attachments",
			projName: "RAPID",
			isFolder: true,
			link: "/qrl/lasertable",
		},
		{
			name: "Laser Table",
			operation: "Vacuum Tube Placement",
			projName: "RAPID",
			isFolder: true,
			link: "/qrl/lasertable",
		},
		{
			name: "Laser Table",
			operation: "Laser Head Removal & Reinstallation",
			projName: "RAPID",
			isFolder: false,
			link: "/qrl/lasertable",
		},
		{
			name: "Laser Table",
			operation: "Pen Installation",
			projName: "RAPID",
			isFolder: false,
			link: "/quiz/lasertable",
		},
		{
			name: "Laser Table",
			operation: "Good vs. Bad Welds",
			projName: "RAPID",
			isFolder: true,
			link: "/qrl/lasertable",
		},
	]

	const walleAssets: asset[] = [
		{
			name: "WallE",
			operation: "Bladder Assembly",
			projName: "RAPID",
			isFolder: false,
			link: "/quiz/walle",
		},
		{
			name: "WallE",
			operation: "Bladder Cleaning",
			projName: "RAPID",
			isFolder: false,
			link: "/quiz/walle",
		},
		{
			name: "WallE",
			operation: "Bladder Removal",
			projName: "RAPID",
			isFolder: false,
			link: "/quiz/walle",
		},
		{
			name: "WallE",
			operation: "ClearWeld Sprayer Testing",
			projName: "RAPID",
			isFolder: true,
			link: "/qrl/walle",
		},
		{
			name: "WallE",
			operation: "MOLLE Attachments",
			projName: "RAPID",
			isFolder: true,
			link: "/qrl/walle",
		},
		{
			name: "WallE",
			operation: "Vacuum Tube Placement",
			projName: "RAPID",
			isFolder: true,
			link: "/qrl/walle",
		},
		{
			name: "WallE",
			operation: "Laser Head Removal & Reinstallation",
			projName: "RAPID",
			isFolder: false,
			link: "/quiz/walle",
		},
		{
			name: "WallE",
			operation: "Pen Installation",
			projName: "RAPID",
			isFolder: false,
			link: "/quiz/walle",
		},
		{
			name: "WallE",
			operation: "Good vs. Bad Welds",
			projName: "RAPID",
			isFolder: true,
			link: "/qrl/walle",
		},
	]



	useEffect(() => {
		console.log(name);
		// push both arrays into one array
		setSharedAssets([...laserTableAssets, ...walleAssets])
	},[])

	const buildings = [
		"test",
	]

	const toLowerCaseAndRemoveSpaces = (str: string) => {
		return str.toLowerCase().replace(/\s/g, '');
	}

	return (
		<posts className={className}>
			<div className="back"><a href="/dashboard"><FaArrowLeft size={30} color={"white"} /></a></div>
			<div className="filter">
				{/* <div className="greeting">
					<p>Welcome to your QRL</p>
				</div> */}
				<button onClick={() => {
					setNotesVisible(notesVisible == "hidden" ? "" : "hidden")
					setTableVisible(tableVisible == "" ? "hidden" : "")
				}}>Info / Notes</button>
				<div className="crumb-container">
					<p>You have selected: <span>Laser Table</span></p>
				</div>
				{/* {true && (
				<Link to="/create/collection">
				<button>
					Upload
					<CloudUpload />
				</button>
				</Link>
				)} */}
				
			</div>
			<PostDisplay user={user} />

			<div className="folder-list">
				<div className="crumb-container-mobile">
					<select onChange={(e) => {setBuilding(e.target.value)}} value={building}>
						{buildings.map((building, index) => {
							return (
								<option key={index} value={building}>{building}</option>
							)
						})}
					</select>
					<p>You have selected:</p>
					<p><span>{building}</span></p>
					<button onClick={() => { 
					setNotesVisible(notesVisible == "hidden" ? "" : "hidden")
					setTableVisible(tableVisible == "" ? "hidden" : "")
						}}>About / Info</button>
				</div>
				<div className={`mobile-about ${notesVisible}`}>
					<Notes>
						<div className="placeholder-body">
							<h2>No Notes! <FaSadTear /></h2>
							<p>No one has posted a note on this technology</p>
						</div>
					</Notes>
					<AssetInfo>
						{/* <img src={hillberryPhoto} /> */}
						<div>
							<h2>Laser Table</h2>
							<p>Machine Arm</p>
							<ul>
							<li>Front Panel</li>
							<li>Back Panel</li>
							<li>Button Bearing</li>
							</ul>
						</div>
					</AssetInfo>
				</div>
			{name == "lasertable" && laserTableAssets.map((asset, index) => {
				return (
					<a href={asset.isFolder ? `/QRL/${name}/${asset.operation}` : `/quiz/${toLowerCaseAndRemoveSpaces(asset.name)}`} className={`folder ${tableVisible}`} key={index}>
						<img src={asset.isFolder ? folderIcon : Quiz} />
						{asset.operation}
					</a>
				)
			})}
			{name == "walle" && walleAssets.map((asset, index) => {
				return (
					<a href={`/QRL/${name}/{asset.operation}`} className={`folder ${tableVisible}`} key={index}>
						<img src={asset.isFolder ? folderIcon : Quiz} />
						{asset.operation}
					</a>
				)
			})}
			{name == "all" && sharedAssets.map((asset, index) => {
				return (
					<a href={`/QRL/${name}/{asset.operation}`} className={`folder ${tableVisible}`} key={index}>
						<img src={asset.isFolder ? folderIcon : Quiz} />
						{asset.operation}
					</a>
				)
			})}
			</div>
			<div className={`folder-table ${tableVisible}`}>
				<table>
					<tr>
						<th>Technology</th>
						<th>Project Name</th>
						<th>Operation</th>
						<th>Type</th>
						<th>Notes</th>
						<th>Date Modified</th>
						{/* <th>Actions</th> */}
					</tr>
					<tr className="search-bars">
						<th><img src={SearchIcon} /><input placeholder='search by ' /></th>
						<th><img src={SearchIcon} /><input placeholder='search by ' /></th>
						<th><img src={SearchIcon} /><input placeholder='search by ' /></th>
						<th><img src={SearchIcon} /><input placeholder='search by ' /></th>
						<th><img src={SearchIcon} /><input placeholder='search by ' /></th>
						<th></th>
					</tr>
					{name == "lasertable" && laserTableAssets.map((asset, index) => {
						return (
							
							<tr key={index} className="data-row">
								<td>{asset.name}</td>
								<td>{asset.projName}</td>
								<td>{asset.operation}</td>
								<td>
									{asset.isFolder ?
									<Link to={`${asset.link}`}><img src={folderIcon} />
									Folder
									</Link>
									:
									<Link to={`${asset.link}`}><img src={Quiz} />
									Course
									</Link>
									}
								</td>
								<td><SlSpeech /></td>
								<td>10/27/2023</td>
								{/* <td className="settings">
									QR 
									<FaRegEye size={20} />
									<FaDownload size={20} />
									<FaCogs size={20} />
									<FaTrash size={20} />
								</td> */}
							</tr>
						)
					})}
					{name == "walle" && walleAssets.map((asset, index) => {
						return (
							
							<tr key={index} className="data-row">
								<td>{asset.name}</td>
								<td>{asset.projName}</td>
								<td>{asset.operation}</td>
								<td>
									{asset.isFolder ?
									<Link to={`${asset.link}`}><img src={folderIcon} />
									Folder
									</Link>
									:
									<Link to={`${asset.link}`}><img src={Quiz} />
									Course
									</Link>
									}
								</td>
								<td><SlSpeech /></td>
								<td>10/27/2023</td>
								{/* <td className="settings">
									QR 
									<FaRegEye size={20} />
									<FaDownload size={20} />
									<FaCogs size={20} />
									<FaTrash size={20} />
								</td> */}
							</tr>
							
						)
					})}
					{name == "all" && sharedAssets.map((asset, index) => {
						return (
							
							<tr key={index} className="data-row">
								<td>{asset.name}</td>
								<td>{asset.projName}</td>
								<td>{asset.operation}</td>
								<td>
									{asset.isFolder ?
									<Link to={`${asset.link}`}><img src={folderIcon} />
									Folder
									</Link>
									:
									<Link to={`${asset.link}`}><img src={Quiz} />
									Course
									</Link>
									}
								</td>
								<td><SlSpeech /></td>
								<td>10/27/2023</td>
								{/* <td className="settings">
									QR 
									<FaRegEye size={20} />
									<FaDownload size={20} />
									<FaCogs size={20} />
									<FaTrash size={20} />
								</td> */}
							</tr>
						)
					})}
				</table>
			</div>
			<div className={`about-container ${notesVisible}`}>
				<Notes>
					<div className="placeholder-body">
						<h2>No Notes! <FaSadTear /></h2>
						<p>No one has posted a note on this technology</p>
					</div>
				</Notes>
				<AssetInfo>
					<img src={Step1}/>
					<div>
						<h2>Laser Table</h2>
						<p>RAPID</p>
						<ul>
							<li>Bladder</li>
							<li>Pen</li>
							<li>ClearWeld</li>
						</ul>
					</div>
				</AssetInfo>
			</div>
		</posts>
	);
};

export default styled(QRLHome)`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	/* height: 100%; */
	overflow: auto;

	& .back {
		display: none;
	}

	.about-container {
		width: 95%;
		height: 80vh;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-content: center;
		background: #fff;
		/* margin-top: 20vh; */
		border-radius: 1rem 1rem 1rem 1rem;
		filter: drop-shadow(10px 10px 10px rgba(0, 0, 0, 0.5));

		& .placeholder-body {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			height: 75vh;
			width: 100%;

			& h2 {
				margin: 0;
				text-decoration: none;
				color: #1f1f1f;
				font-weight: 600;
				font-style: none;
			}

			& p {
				margin: 0;
				text-decoration: none;
				color: #1f1f1f;
				font-weight: 400;
				font-style: none;
			}
		}

		& content {
			display: flex;
			flex-direction: column;
			background-color: var(--primary-light);
			margin-top: 1rem;
			padding: 1rem;
			border-radius: 1rem;
			justify-content: center;
			align-items: center;
			height: 75vh;
			margin-right: 1rem;

			& div {
				background-color: #fff;
				padding: 1rem;
				border-radius: 1rem;
				margin: .2rem;
				width: 80%;
			}

			& img {
				width: 30%;
				border-radius: 1rem;
				max-width: 80%;
			}
		}
	}

	.about-container.hidden {
		display: none;
	}

	.folder-table.hidden {
		display: none;
	}

	.search-bars {
		align-items: center;
		margin: auto;
		height: 1.5rem;
		

		input {
			border: none;
			border-radius: 0 1rem 1rem 0;
			height: 1.5rem;
			margin: .5rem 0;
			width: 80%;
		}

		img {
			width: 1rem;
		}
	}

	.settings {
		& svg {
			margin-left: .5rem;
		}	
	}



	.filter {
		margin-top: 5vh;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-around;
		width: 100%;

		& button {
			border: none;
			border-radius: 1rem;
			margin: .5rem;
			padding: .5rem;
			width: 12rem;

			& svg {
				max-width: 2rem;
				margin-left: 1rem;
			}
		}

		& p {
			color: black;
			font-weight: 500;
			margin-left: 1rem;
			background-color: var(--secondary);
			padding: .5rem;
			border-radius: 1rem;
		}

		& .greeting {
			margin-top: 1rem;
			margin-right: 1rem;
			& p {
				background-color: var(--secondary);
			}
			
		}

		& .crumb-container {
			padding: .5rem;
			border-radius: 1rem;
			margin-top: 1rem;

			& span {
				background-color: var(--tertiary);
				border-radius: 1rem;
				padding: .2rem;
			}
		}
	}

	.folder-list {
		display: none;
	}

	.folder-table {
		width: 96%;
		max-height: 80vh;
		overflow-y: scroll;
		display: flex;
		flex-direction: column;
		/* justify-content: center; */
		align-content: center;
		background: #fff;
		/* margin-top: 20vh; */
		border-radius: 1rem 1rem 1rem 1rem;
		filter: drop-shadow(10px 10px 10px rgba(0, 0, 0, 0.5));
		overflow-x: auto;
	}

	table {
		max-width: 100%;
		width: 100%;
		height: 80vh;
		align-items: center;
		border-collapse: collapse;
		table-layout: fixed;

		& th {
			max-height: 5vh;
			text-align: left;
			word-wrap: break-word;
		}

		& tr {
			max-height: 5vh;

		}

		& a {
			text-decoration: none;
			color: black;
		}

		& tr:nth-child(even) {
			background-color: var(--primary-light);
		}

		& td {
			padding: 1rem;
			text-align: left;
			word-wrap: break-word;
			
			 & p {
				margin: 1rem;
			 }
		}

		& button {
			max-height: 5vh;
		}

		& img {
			width: 2rem;
		}

		& p {
			margin: 0;
		}

		& button {
			border: none;
			border-radius: 1rem;
			padding-left: 1rem;
			padding-right: 1rem;
			text-align: center;
			filter: drop-shadow(10px 10px 10px rgba(0, 0, 0, 0.5));
		}
	}

	& .mobile-about.hidden {
			display: none;
	}

	& .mobile-about {
		display: none;
	}


	@media screen and (max-width: 1150px) {

		& .filter {
			margin-top: 5vh;

			& p {
				font-size: x-small;
				margin: 0;
			}
		}

		.folder-table {
			width: 100%;
			
		}
		th, td {
			font-size: small;
		}
	}

	@media screen and (max-width: 480px) {
		width: 100%;
		& .filter {
			margin-top: 5vh;

			& p {
				font-size: x-small;
				margin: 0;
			}
		}

		& .back {
			position: absolute;
			z-index: 1000;
			display: block;
			top: 1rem;
			left: .7rem;
		}

		.crumb-container-mobile {
			display: block;
			padding: .5rem;
			border-radius: 1rem;
			text-align: center;
			width: 100%;

				& p {
					color: black;
					font-weight: 500;
					background-color: var(--secondary);
					padding: .5rem;
					border-radius: 1rem;
				}

				& span {
					background-color: var(--tertiary);
					border-radius: 1rem;
					padding: .2rem;
				}

				& select {
					border-radius: 1rem;
					border: none;
					padding: .5rem;
					margin-bottom: .5rem;
				}
			}

		.folder-list {
			margin: auto;
			margin-top: 27vh;
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: center;

			& button {
				border: none;
				border-radius: 1rem;
				padding: .5rem;
			}

			& img {
				width: 100%;
				border-radius: 1rem;
			}
		}

		& .mobile-about {
			display: flex;
			background-color: white;
			margin: 1rem;
			border-radius: 1rem;
			padding: 1rem;
			flex-direction: column;
			justify-content: center;
			align-items: center;
		}

		& .mobile-about.hidden {
			display: none;
		}

		& .folder.hidden {
			display: none;
		}

		& .about-container {
			display: none;
		}

		& .folder {
			background-color: gray;
			border-radius: 1rem;
			margin: .2rem;
			width: 43vw;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			font-style: normal;
			font-weight: 600;
			text-decoration: none;
			color: black;
		}

		& img {
			width: 40vw;
			margin: auto;
			padding: .2rem;
			border-radius: 1rem;
		}

		table {
			display: none;
		}

		.filter {
			display: none;
		}
	}
`;
