import { FC } from 'react';
import styled from 'styled-components';
import LeftSideBar from '../II-DASHBOARD/components/LeftSideBar';
import Header from '../../GlobalShell/Header';

import notesIcon from "../../assets/png/Notes_Icon-01.png"

const Notes: FC<X> = ({ className }) => {
    const feedNotes = [
        {
            title: "Troubleshooting York Air Cooled Scroll Chiller",
            content: "Trouble Shooting Procedures",
            link: "/notes",
        },
        {
            title: "test",
            content: "Trouble Shooting Procedures",
            link: "/notes",
        },
        {
            title: "test",
            content: "Trouble Shooting Procedures",
            link: "/notes",
        },
        {
            title: "test",
            content: "Trouble Shooting Procedures",
            link: "/notes",
        },
        {
            title: "test",
            content: "Trouble Shooting Procedures",
            link: "/notes",
        },
        {
            title: "test",
            content: "Trouble Shooting Procedures",
            link: "/notes",
        },
        {
            title: "test",
            content: "Trouble Shooting Procedures",
            link: "/notes",
        },
        {
            title: "test",
            content: "Trouble Shooting Procedures",
            link: "/notes",
        },
    ]

	const notifications = [
		{
			
		}
	]
    return (
        <notes className={className}>
            <Header />
            <LeftSideBar />
			<div className="notes-section">
					<div className="content">
						{feedNotes.map((note, i) => {
							return (
								<div className="single-post">

									<div className="image-container">
										<img src={notesIcon}></img>
									</div>

									<div className="text-container">
										<h3>{note.title}</h3>
										<p>{note.content}</p>
										<p>Original Post: {note.link}</p>
									</div>

								</div>
							)
						})}
					</div>
			</div>
        </notes>
    )
}

export default styled(Notes)`
    height: 100%;
    width: 100%;
    background-color: var(--primary);

	.notes-section {
		width: 90%;
		margin: auto;
	}

	& .content {
		width: 100%;
        max-height: 70vh;
		display: flex;
		flex-wrap: nowrap;
		flex-direction: column;
		/* justify-content: center; */
		background: #fff;
        overflow-y: auto;
		margin: auto;
		margin-top: 25vh;
		margin-left: 15vw;
		border-radius: 1rem;
		filter: drop-shadow(10px 10px 10px rgba(0, 0, 0, 0.5));

		& .single-post {
			display: flex;
			flex-direction: column;
			border-bottom: solid gray;
			padding: 1.5rem;

			& .image-container {
				background-color: gray;
				width: 100%;
				height: 15rem;
				border-radius: 1rem;
				display: flex;
				justify-content: center;
				align-content: center;
				padding: 10px;

				& img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}

			& h3 {
				margin-left: 1rem;
			}

			& p {
				margin-left: 1rem;
			}

			& .buildings {
				display: flex;
				font-size: small;
				font-style: italic;
				
				
				& p {
					padding: 2px;
					color: var(--primary);
				}
			}

			& .icons {
				position: absolute;
				right: 1rem;

				& svg {
					margin: 5px;
					color: var(--primary)
				}
			}
		}
	}

        @media screen and (max-width: 480px) {
			height: 100%;
			justify-content: center;

			& .content {
				margin: 25vh auto;
				height: 100%;
				width: 100%;
			}

				& .single-post {
					background-color: white;
					padding: 1rem;
					margin: 1rem;
					border-radius: 1rem;
					font-weight: 500;
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-content: center;

					& h3 {
						border-bottom: 2px solid gray;
					}
				}

				& .image-container {
					display: flex;
					margin: auto;
					align-content: center;
					justify-content: center;
					background-color: #2c2c2c;
					border-radius: 1rem 1rem 0 0;
					padding: .5rem;

					& img {
						margin: auto;
					}
				}

				& .buildings {
					color: green;
					font-style: italic;
				}

				& svg {
					float: right;
					margin: .5rem;
				}


		}
`