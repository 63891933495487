// ----------------------
// ROUTE CHANGES ONLY
// FOR CONTENT UPDATES SEE ROUTE DIRECTORIES ' ex: Home/page.tsx'
// - TT
// ----------------------

import { Route, Routes } from 'react-router-dom';
import Home from './Home/page';
import Folder from './Folder/page'

interface props {
	buildingSelected?: string;
	machineSelected?: string;
}

const LibraryRouter:React.FC <props> = ({ buildingSelected, machineSelected }) => {

	return (
		<Routes>
			<Route
				path="/:name"
				element={<Home buildingSelected={buildingSelected} />}
			/>
			<Route
				path="/:name/:folder"
				element={<Folder buildingSelected={buildingSelected} />}
			/>
		</Routes>
	);
};

export default LibraryRouter;
