import styled from "styled-components";
import Button from "../../GlobalShell/utils/Button";
import { useState } from "react";
import { Link } from "react-router-dom";

interface props {
    className?: string;
}


const MobileLoginForm: React.FC<props> = ({ className }) => {

    const [logInActive, setLogInActive] = useState("active");
    const [signUpActive, setSignUpActive] = useState("");

    const handlePanelSwap = () => {
        logInActive == "active" ? setLogInActive("") : setLogInActive("active");
        signUpActive == "active" ? setSignUpActive("") : setSignUpActive("active");
    }


    return (
        <section className={className}>
            <h2>Sign In</h2>
            <form className={`form ${logInActive}`}>
                <label>Username</label>
                <input
                    type="text"
                    placeholder="Enter Username"
                />

                <label>Password</label>
                <input
                    type="password"
                    placeholder="Enter password"
                />
                <div className="remember">
                    <input id="remember" type="checkbox" />
                    <label>Remember Me</label>
                </div>
                <Link to="/dashboard">
                <Button
                    type="fill"
                    color="orange"
                    link="/dashboard"
                >
                    Login
                </Button>
                </Link>
            </form>

            <form className={`form ${signUpActive}`}>
                <label>First Name</label>
                <input
                    type="text"
                    placeholder="Enter Your First Name"
                />
                <label>Last Name</label>
                <input
                    type="text"
                    placeholder="Enter Your Last Name"
                />
                <label>Username</label>
                <input
                    type="text"
                    placeholder="Enter Your Username"
                />
                <label>Password</label>
                <input
                    type="text"
                    placeholder="Enter Your Password"
                />
                <label>Access Code</label>
                <input
                    type="text"
                    placeholder="Enter Your Access Code"
                />
                <Link to="/dashboard">
                <Button
                    type="fill"
                    color="orange"
                    link="/dashboard"
                >
                    Sign Up
                </Button>
                </Link>
            </form>

            <div className="help">
                <a href="#">Forgot your password?</a>
                <p onClick={handlePanelSwap}>Don't have an account? Sign up</p>
            </div>
        </section>
    )
}

export default styled(MobileLoginForm)`
    display: none;

    @media screen and (max-width: 480px) {
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin: auto;
        background-image: linear-gradient(to right bottom, #ffffff52, rgba(255, 255, 255, 0));
        backdrop-filter: blur(10px);
        box-shadow: rgba(30, 30, 30, 0.2) 10px 10px 10px;
        width: 100vw;
        border-radius: 1rem;

        .form {
            display: none;
        }

        h2 {
            text-align: center;
            margin-top: 1rem;
            color: white;
            font-size: 2rem;
        }

        .form.active {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 40vh;
            padding: 1rem;
        }

        label {
            color: white;
            margin-bottom: 1em;
            margin-top: 1em;
        }

        input {
            border: none;
            width: 80%;
            height: 3rem;
            border-radius: 1rem;
        }

        .help {
            margin-bottom: 1em;
            display: flex;
            justify-content: center;
            flex-direction: column;
            font-size: 1rem;

            & a {
                text-decoration: none;
                text-align: center;
                color: white;
                margin: .5rem;
            }

            & p {
                text-align: center;
                color: white;
                margin: .5rem;
                cursor: pointer;
            }
        }

        .remember {
            display: flex;
            width: 100%;
            justify-content: center;
            align-items: center;
            margin-left: 25%;

            & label {
                width: 100%;
            }

            #remember {
                width: 1.5em;
                margin-right: 1em;
            }
        }
    }
`;