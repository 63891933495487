import { useState, useEffect } from 'react';
import { getLoggedInUser } from '../../../controllers';

type User = {
	_id: string;
	savedBlobs: [];
	userName: string;
	notes: [];
};

const HandleUser = () => {
	const [user, setUser] = useState<User>({
		_id: '',
		savedBlobs: [],
		userName: '',
		notes: [],
	});

	useEffect(() => {
		const userId = localStorage.getItem('user_id');
		if (userId) {
			const returnUserInfo = async () => {
				try {
					const res = await getLoggedInUser(userId);
					setUser(res);
				} catch (err) {
					console.error({ error: err });
				}
			};
			returnUserInfo();
		}
	}, []);

	return { user };
};

export { HandleUser };
