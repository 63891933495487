import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';

import Header from '../../GlobalShell/Header';
import LeftSideBar from '../II-DASHBOARD/components/LeftSideBar';

import { siemens } from '../../assets';
import WeldQuality from '../../assets/kennon/LaserTable2.jpg'
import LaserHead from '../../assets/kennon/LaserTable1.jpg'
import content from '../../assets/siemens/Front_Panel.mp4'
import overview from '../../assets/siemens/jeanProc.pdf'
import pocketBagStep from '../../assets/siemens/pocketBagStep.pdf';
import pocketFacingStep from '../../assets/siemens/pocketFacingStep.pdf';
import stayStitchStep from '../../assets/siemens/stayStitchStep.pdf';
import Step from './components/step'
// import LaserTablePrep from '../../assets/kennon/Removing and Reinstalling the Laser Head.mp4'

import Step1 from '../../assets/kennon_steps/Step1.jpg'
import Step1_2 from '../../assets/kennon_steps/Step1_2.jpg'
import Step1_3 from '../../assets/kennon_steps/Step1_3.jpg'
import Step1_4 from '../../assets/kennon_steps/Step1_4.jpg'
import Step1_5 from '../../assets/kennon_steps/Step1_5.jpg'
import Step1_6 from '../../assets/kennon_steps/Step1_6.jpg'
import Step2_1 from '../../assets/kennon_steps/Step2_1.png'
import Step2_2 from '../../assets/kennon_steps/Step2_2.png'
import Step2_3 from '../../assets/kennon_steps/Step2_3.png'
import Step2_4 from '../../assets/kennon_steps/Step2_4.png'
import Step2_5 from '../../assets/kennon_steps/Step2_5.png'
import Step3_1 from '../../assets/kennon_steps/Step3_1.jpg'
import Step3_2 from '../../assets/kennon_steps/Step3_2.jpg'
import Step4_1 from '../../assets/kennon_steps/Step4_1.jpg'
import Step4_2 from '../../assets/kennon_steps/Step4_2.jpg'
import Step5_1 from '../../assets/kennon_steps/Step5_1.png'
import Step5_2 from '../../assets/kennon_steps/Step5_2.jpg'
import Step5_3 from '../../assets/kennon_steps/Step5_3.png'
import Step5_4 from '../../assets/kennon_steps/Step5_4.jpg'
import Step5_5 from '../../assets/kennon_steps/Step5_5.jpg'
import Step5_6 from '../../assets/kennon_steps/Step5_6.jpg'
import Step6_1 from '../../assets/kennon_steps/Step6_1.png'
import Step6_2 from '../../assets/kennon_steps/Step6_2.png'
import Step6_3 from '../../assets/kennon_steps/Step6_3.png'
import Step6_4 from '../../assets/kennon_steps/Step6_4.png'
import Step7_1 from '../../assets/kennon_steps/Step7_1.jpg'
import Step7_2 from '../../assets/kennon_steps/Step7_2.jpg'

import walleStep0_1 from '../../assets/walle_steps/Step0_1.jpg';
import walleStep0_2 from '../../assets/walle_steps/Step0_2.jpg';
import walleStep1_1 from '../../assets/walle_steps/Step1_1.jpg';
import walleStep1_2 from '../../assets/walle_steps/step1_2.jpg';
import walleStep1_2_2 from '../../assets/walle_steps/Step1_2_2.jpg';
import walleStep2_1 from '../../assets/walle_steps/Step2_1.jpg';
import walleStep2_2 from '../../assets/walle_steps/Step2_2.jpg';
import walleStep2_2_2 from '../../assets/walle_steps/Step2_2_2.jpg';
import walleStep2_3 from '../../assets/walle_steps/Step2_3.png';

import { useNavigate, useParams } from 'react-router-dom';

interface props extends X {
    children?: any;
}

type Step = {
    stepName: string;
    machine: string;
    workInstructions: string[];
    keyPoint: string | null;
    pictures: string[];
    safetyNote: string | null;
    inspect: string | null;
    isComplete: boolean;
}

const QuizPage: FC<props> = ({ className, children }) => {

    const navi = useNavigate();

    const { name } = useParams();

    const [activeSteps, setActiveSteps] = useState<Step[]>([]);

    const [activePage, setActivePage] = useState(0);
    const [isCourseCompleted, setIsCourseCompleted] = useState(false);

    const stepsPerPage = 1;

    const totalPages = Math.ceil(activeSteps.length / stepsPerPage);
    const startIdx = activePage * stepsPerPage;
    const visibleSteps = activeSteps.slice(startIdx, startIdx + stepsPerPage);

    const handleNextPage = () => {
        setActivePage((prevPage) => Math.min(prevPage + 1, totalPages - 1));
    };

    const handlePrevPage = () => {
        setActivePage((prevPage) => Math.max(prevPage - 1, 0));
    };

    const handleComplete = () => {
        if (activePage === totalPages - 1) {
            setIsCourseCompleted(true);
        }
        navi("/dashboard");
    }

    const laserTableSteps: Step[] = [
        {
            stepName: "Power On",
            machine: "Laser Table",
            workInstructions: [
                "Turn on the isobar switch",
                "Turn on the Laser Control Box",
                "Turn on the Laser Table",
                "Select FIND HOME on the control panel",
                "Press the blinking START button on the laser table",
                "Open the AT System Overview program",
            ],
            keyPoint: "If it asks for product feedback, to get around the error, exit/close the pop-up window. In the Search function in the Taskbar, type sc start novocat.",
            pictures: [Step1, Step1_2, Step1_3, Step1_4, Step1_5, Step1_6],
            safetyNote: "Wear safety glasses while operating the laser table",
            inspect: "",
            isComplete: false,
        },
        {
            stepName: "Fill the ClearWeld Syringe",
            machine: "Laser Table",
            workInstructions: [
            ],
            keyPoint: "",
            pictures: [""],
            safetyNote: "",
            inspect: "",
            isComplete: false,
        },
        {
            stepName: "Check Table Offsets",
            machine: "Laser Table",
            workInstructions: [
                "Open the PatternSmith program.",
                "Select the Toolbox Menu from the right side of the screen.",
                "Hover over the Pen and click on the cog icon.",
                "Set the Pen offsets. Click OK to apply.",
                "Click on the Spray tool cog icon and set the spray offsets, Click OK to apply.",
            ],
            keyPoint: "",
            pictures: [Step2_1, Step2_2, Step2_3, Step2_4, Step2_5],
            safetyNote: "",
            inspect: "",
            isComplete: false,
        },
        {
            stepName: "Remove & Install the Laser Head",
            machine: "Laser Table",
            workInstructions: [
                "Locate the screw securing the laser head in place.",
                "Using a T-handle allen wrench, loosen the screw",
            ],
            keyPoint: "",
            pictures: [Step3_1, Step3_2],
            safetyNote: "",
            inspect: "",
            isComplete: false,
        },
        {
            stepName: "Clean The Laser Roller Head",
            machine: "Laser Table",
            workInstructions: [
                "At the bottom of the laser head canister is the roller",
                "Using 91% Isopropyl Alcohol and a foam swab, clean the Laser Roller Head by wiping across the surface to remove any dust or debris from the roller head. Manually advance the roller with your finger until the entire roller has been cleaned.",
                "Reinstall the canister when the cleaning is complete"
            ],
            keyPoint: "Wear Safety Gloves For Your Safety When Handling Isopropyl Alcohol",
            pictures: [Step4_1, Step4_2],
            safetyNote: "Don't soak the swab with alcohol, squeeze out any excess until it is just damp before you clean with it",
            inspect: "Look for any cloudy or milky or burn spots on the laser roller at this time. Any flaws will affect the quality of the welds",
            isComplete: false,
        },
        {
            stepName: "Prep Laser Table",
            machine: "Laser Table",
            workInstructions: [
                "Move the gantry out of the way using the joystick on the Control Panel.",
                "Move the silicone layer to reveal the inserts for placing the hardware (Oral Inflator and the Flange Assembly).",
                "The marks on the table will tell you which inserts to use for left bladders and which to use for right bladders, as well as which to use for S/M bladders and L/XL bladders.",
                "Place the Oral Inflator Tube into the appropriate spot on the table and flip the spot on the table for the Flange Assembly.",
                "Turn on the Toko Flat Iron. This is what you will use to pre-weld all of the attachments. The temperature is pre-set so you should not have to adjust anything. When fully heated, the iron will say 130.",
                "There are two metal frames on the table, one nested inside the other. Roll out the material onto the larger outer frame on the table. Make sure the orange part of the material overlaps the outer edges of the metal frame.",
                "Use this opportunity to search for material flaws, like the stretch marks.",
                "Use the longer rods to stretch the material onto the outer frame as shown in the video, stretching opposite sides.",
                "Cut the excess material off of the frame and trim the corners."

            ],
            keyPoint: "Stretch marks are NOT OK on the bladders material, but are acceptable on the flange, MOLLE attachments, and CO2 holster, as those parts won’t be holding air.",
            pictures: [Step5_1, Step5_2, Step5_3, Step5_4, Step5_5, Step5_6],
            safetyNote: "",
            inspect: "",
            isComplete: false,
        },
        {
            stepName: "Plot Top Molle Placement",
            machine: "Laser Table",
            workInstructions: [
                "In PatternSmith, set spacing to 0, 0",
                "Click the Table 1 on the screen to open the editor window for this table",
                "Move the gantry back into place by pressing the HOME button on the Control Panel",
                "Turn the toggle switch to 'PEN' and install the pen.",
                "On the computer, pres F10 to start PatternSmith",
                "On the Control Panel, press the 'PLAY' button to make the CNC table start marking. Then press 'X' once the table has finished marking.",
                "Remove the pen so it doesn't dry out",
                "Use the joystick on the control panel to move the gantry until it no longer covers the metal frames",
            ],
            keyPoint: "Always check the coordinates once the gantry is 'HOME' to make sure they match the coordinates you have set.",
            pictures: [Step6_1, Step6_2, Step6_3, Step6_4],
            safetyNote: "",
            inspect: "",
            isComplete: false,
        },
        {
            stepName: "Pre Weld Molle Attachments",
            machine: "Laser Table",
            workInstructions: [
                "The purpose of pre-welding the attachments is to tack them in place so they don't move during the welding process later.",
                "Center the molle attachment on the outside of the marked lines.",
                "The tab shpuld be facing towards the center of the material piece.",
                "Pre-weld the molle attachments in place.",
            ],
            keyPoint: "Use the clear FEP (plastic) sheet to protect the other surfaces while you weld the MOLLE attachments in place using the Toko flat iron. IRON AT 130 DEGREES.",
            pictures: [Step7_1, Step7_2],
            safetyNote: "",
            inspect: "",
            isComplete: false,
        },
        
    ]


    const walleSteps: Step[] = [
        {
            stepName: "Power On Computer And Laser",
            machine: "WallE",
            workInstructions: [
                "Turn on the operator computer. On the desktop, locate the WALLE CONTROL PANEL and open it.",
                "Turn the laser on by twisting the main power switch on the laser control tower.",
                "When it stops beeping, press the blue RESET button.",
            ],
            keyPoint: "",
            pictures: [walleStep0_1, walleStep0_2],
            safetyNote: "Wear safety glasses when the laser is powered on.",
            inspect: "",
            isComplete: false,
        },
        {
            stepName: "Assemble the Manifold",
            machine: "WallE",
            workInstructions: [
                "Locate the manifold and the 4 screws that go with it.",
                "Place the manifold on the laser table, with the 4 holes lined up with the 4 holes on the table.",
                "Screw the manifold into place.",
            ],
            keyPoint: "",
            pictures: [walleStep1_1, walleStep1_2, walleStep1_2_2],
            safetyNote: "",
            inspect: "",
            isComplete: false,
        },
        {
            stepName: "Prep Molle Attachments",
            machine: "WallE",
            workInstructions: [
                "Locate the Molle attachments.",
                "Place the Molle attachments into the manifold.",
                "Use the Toko flat iron to pre-weld the Molle attachments into place.",
            ],
            keyPoint: "",
            pictures: [walleStep2_1, walleStep2_2, walleStep2_2_2, walleStep2_3],
            safetyNote: "Wear gloves and safety glasses when handling clearweld.",
            inspect: "",
            isComplete: false,
        }
    ]

    useEffect(() => {
        if (name == "lasertable") {
            setActiveSteps(laserTableSteps);
        };

        if (name == "walle") {
            setActiveSteps(walleSteps);
        };
    },[])

    return (
        <section className={`Quiz ${className}`}>
            <Header />
            <LeftSideBar>
                <div className="complete">
                    <h2>Completed Courses</h2>
                    <ul>
                        <li>Course 1</li>
                        <li>Course 2</li>
                        <li>Course 3</li>
                        <li>Course 4</li>
                    </ul>
                </div>
                <div className="incomplete">
                    <h2>Unfinished Courses</h2>
                    <ul>
                        <li>Course 5</li>
                        <li>Course 6</li>
                    </ul>
                </div>
            </LeftSideBar>

            <div className="quiz-body">
                <div className="left">
                    <div>
                        <h1>RAPID</h1>
                    </div>
                    <video className="video-container" controls={true} autoPlay src={""} />
                    {(activePage === totalPages - 1) && (
                        <div className="course-completion">
                            <button onClick={handleComplete}>Complete Course</button>
                        </div>
                    )}
                    {!(activePage === totalPages - 1) && visibleSteps.map((step, i) => {
                        return (
                            <Step
                                index={i}
                                stepName={step.stepName}
                                workInstructions={step.workInstructions}
                                keyPoint={step.keyPoint}
                                pictures={step.pictures}
                                safetyNote={step.safetyNote}
                                isCompleted={step.isComplete}
                            />
                        )
                    })}

                    <div className="pagination-controls">
                        <button onClick={handlePrevPage} disabled={activePage === 0}>
                            Previous Page
                        </button>
                        <span>Page {activePage + 1} of {totalPages}</span>
                        <button onClick={handleNextPage} disabled={activePage === totalPages - 1}>
                            Next Page
                        </button>
                    </div>
                </div>



            </div>
            {children}
        </section>
    );
}

export default styled(QuizPage)`
    background: var(--primary);
    width: 100%;
    /* height: 100%; */
    text-align: center;
    display: flex;
    flex-direction: row;
    /* padding: 1rem; */

    & .course-completion {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin: 1rem;
        margin-top: 1rem;
        padding: 1rem;
        background-color: var(--secondary);
        border-radius: 1rem;
        color: white;

        & button {
            border: none;
            border-radius: 1rem;
            padding: 1rem;
            background-color: var(--primary);
            color: white;
        }
    }

    .complete {
        background-color: var(--secondary);
        border-radius: 1rem;
        font-weight: 600;
        color: white;

        & ul {
            list-style-type: none;
            padding: 0;
        }
    }

    .incomplete {
        background-color: var(--secondary);
        border-radius: 1rem;
        font-weight: 600;
        margin-top: 1rem;

        & ul {
            list-style-type: none;
            padding: 0;
        }
    }

    .pagination-controls {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin: 1rem;
        margin-top: 0;
        padding: 1rem;
        background-color: var(--secondary);
        border-radius: 1rem;
        color: white;

        & button {
            border: none;
            border-radius: 1rem;
            padding: 1rem;
            background-color: var(--primary);
            color: white;
        }
    }

    & .quiz-body {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        width: 80%;
        margin: 1rem;
        margin-top: 8vh;
        background-color: white;
        border-radius: 1rem;
        padding: 1rem;

        & .left {
            display: flex;
            flex-direction: column;
            background-color: white;
            width: 100%;
            height: 100%;
            margin: auto;
            border-radius: 1rem;
            overflow: auto;

            & iframe {
                padding: 1rem;
                margin: auto;
                width: 100%;
                height: 50vh;
                border-radius: 1rem;
            }

            & .complete-entire {
                display: flex;
                border-radius: 1rem;
                background-color: var(--secondary);
                color: white;
                width: 50%;
                margin: auto;
                min-height: 5vh;
                align-items: center;
                justify-content: center;

                & input {
                    margin-right: 1rem;
                }
            }

            & .video-container {
                position: relative;
                width: 100%;
                height: 30em;
            }

            & .video-container video {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }


            
        }   

        & h1 {
            background-color: var(--secondary);
            border-radius: 1rem;
            padding: 1rem;
            color: white;
            margin: 1rem;
        }

        & .question {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin-top: 1rem;

            & input {
                border-radius: 1rem;
            }
        }
    }

    @media screen and (max-width: 768px) {
        display: flex;
        justify-content: center;
        /* flex-direction: column; */

        & .quiz-body {
            width: 100%;

            margin-top: 27vh;
            border-radius: 0;
            padding: .5rem;

            & .left {
                width: 100%;
                margin: 0;
                border-radius: 0;
                overflow: auto;

                & iframe {
                    padding: 0;
                    margin: 0;
                    width: 100%;
                    height: 50vh;
                    border-radius: 0;
                }

                & .complete-entire {
                    width: 100%;
                    margin: 0;
                    border-radius: 0;
                    min-height: 5vh;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }
`