import image1 from './siemens/20230817_122131.jpg';
import image2 from './siemens/20231115_131631.jpg';
import image3 from './siemens/20230817_122241.jpg';
import image4 from './siemens/20230817_122316.jpg';
import attachFlySaitex from './siemens/Attach Fly - Saitex.png';
import frontPanel from './siemens/front panel.png';
import image7 from './siemens/image-20230708-220626-b9066675.jpeg';
import image8 from './siemens/image-20230708-221408-92355f34.jpeg';
import image9 from './siemens/image-20230708-221616-abfce62b.jpeg';
import image10 from './siemens/image-20230708-221640-d44edf0e.jpeg';
import image11 from './siemens/image-20230708-221706-7395f771.jpeg';
import image12 from './siemens/image-20230708-221726-06d479fd.jpeg';
import image13 from './siemens/image-20230708-221949-71da533e.jpeg';
import image14 from './siemens/image-20230708-222000-9e745126.jpeg';
import image15 from './siemens/image-20230708-222127-e404154f.jpeg';
import image16 from './siemens/image-20230708-222143-c0a5e807.jpeg';
import image17 from './siemens/image-20230708-222156-621d7cff.jpeg';
import image18 from './siemens/image-20230708-222412-b432293f.jpeg';
import image20 from './siemens/image-20230708-222416-06bab39b.jpeg';
import image21 from './siemens/image-20230708-222432-c485ac41.jpeg';
import image22 from './siemens/image-20230708-222822-1089082e.jpeg';
import image23 from './siemens/image-20230708-222916-89c48879.jpeg';
import image24 from './siemens/image-20230709-035029-3ce57a05.jpeg';
import image25 from './siemens/image-20230709-035054-e406d106.jpeg';
import image26 from './siemens/image-20230709-035055-8fd5e342.jpeg'
import image27 from './siemens/image-20230710-225027-90be0a64.jpeg';
import image28 from './siemens/image-20230710-225426-5851907a.jpeg';
import image29 from './siemens/image-20230711-030600-15a09b07.jpeg';
import pocketBags from './siemens/Pocket Bags - Saitex.png'
import sideSeamOrientation from './siemens/Side Seam Orientation.png';
import sideSeamPieces from './siemens/Side Seam Pieces.png';
import stayStitchPocketBag from './siemens/Staystitch Pocket Bag - Saitex.png'
import video1 from './siemens/video2.mov';

export const siemens = [
    image1,
    image2,
    image3,
    image4,
    attachFlySaitex,
    frontPanel,
    image7,
    image8,
    image9,
    image10,
    image11,
    image12,
    image13,
    image14,
    image15,
    image16,
    image17,
    image18,
    image20,
    image21,
    image22,
    image23,
    image24,
    image25,
    image26,
    image27,
    image28,
    image29,
    pocketBags,
    sideSeamOrientation,
    sideSeamPieces,
    stayStitchPocketBag,
    video1
]