import { FC, useEffect } from 'react';
import styled from 'styled-components';

import newsIcon from "../../../../assets/png/News_Icon.png"
import { FaBookmark, FaEye } from 'react-icons/fa';

import { siemens } from '../../../../assets';

import Step1_1 from '../../../../assets/kennon_steps/Step1.jpg'
import Step1_2 from '../../../../assets/kennon_steps/Step1_2.jpg'
import Step1_3 from '../../../../assets/kennon_steps/Step1_3.jpg'
import Step1_4 from '../../../../assets/kennon_steps/Step1_4.jpg'

const placeholderImage = require("../../../../assets/waynestate.png")



interface props {
    className?: string;
}

type Post = {
	src: string,
	title: string,
}

const NewsHome: FC <props> = ({ className }) => {

	const openPDF = (src: any) => {
		const pdfURL = process.env.PUBLIC_URL + src;
		window.open(pdfURL, '_blank');
	}

	const arr = [
		{
			name: "Turn on the Isobar Switch",
			procedure: "Power On",
			src: Step1_1,
			details: "",
			datemodified: "11/29/2023",
		},
		{
			name: "Turn on the laser control box",
			procedure: "Power On",
			src: Step1_2,
			details: "",
			datemodified: "22/29/2023",
		},
		{
			name: "Turn on the Laser Table",
			procedure: "Power On",
			src: Step1_3,
			details: "",
			datemodified: "11/29/2023",
		},
		{
			name: "Press the Blinking Start Button",
			procedure: "Power On",
			src: Step1_4,
			details: "",
			datemodified: "11/29/2023",
		},
	]

	useEffect(() => {
		console.log(arr);
	},[]);

    return (
        <news className={className}>
            <div className="gold-topper">
				<div className="content">
					<h1>News Feed</h1>
					<img className="news-icon" src={newsIcon}></img>
					<h3>Scroll Through Newly Added & Edited Content</h3>
					{arr.map((post, i) => {
						return (
							<div className="single-post">

								<div className="image-container">
									<img onClick={() => openPDF("AirHandlingPDF")} src={post.src}></img>
								</div>

								<div className="text-container">
									<h3>{post.name}</h3>
									<p>Procedure: {post.procedure}</p>
									<p>Details: {post.details}</p>
									<p>Date Modified: {post.datemodified}</p>
								</div>
								<FaBookmark size={30} />
								<FaEye size={30} />
							</div>
						)
					})}
				</div>
			</div>
        </news>
    )
}

export default styled(NewsHome)`
    height: 100%;
	display: flex;
	flex-direction: column;

	& .news-icon {
		margin-top: .5rem;
		margin-bottom: .5rem;
	}

	& h3 {
		text-align: center;
	}

	& h1 {
		color: white;
		text-align: center;
		margin: 0;
		padding: 1rem;
		font-weight: 700;
		font-style: italic;
	}
	

	img {
		width: 50%;
	}

	.news {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 18vh;
		position: absolute;
		filter: drop-shadow(10px 10px 10px rgba(0, 0, 0, 0.25));
		z-index: 10;
	}

	.content {
		width: 90%;
		background-color: gray;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin: auto;
		margin-top: 30vh;
		border-radius: 1rem;
		filter: drop-shadow(10px 10px 10px rgba(0, 0, 0, 0.25));

		& .single-post {
			background-color: white;
			padding: 1rem;
			margin: 1rem;
			border-radius: 1rem;
			font-weight: 500;

			& h3 {
				border-bottom: 2px solid gray;
			}
		}

		& .image-container {
			display: flex;
			margin: auto;
			align-content: center;
			justify-content: center;
			background-color: #2c2c2c;
			border-radius: 1rem 1rem 0 0;
			padding: .5rem;

			& img {
				margin: auto;
				background-repeat: no-repeat;
				width: 100%;
				height: 100%;
			}
		}

		& .buildings {
			color: green;
			font-style: italic;
		}

		& svg {
			float: right;
			margin: .5rem;
		}
	}
`