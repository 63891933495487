import { FC, useState } from 'react';
import styled from 'styled-components';

import { ReactComponent as CloudUpload } from '../../../../assets/tool_icons/Cloud_Upload_Black.svg'

import PDF from '../../../../assets/png/PDF_Icon-01.png'
import PNG from '../../../../assets/png/PNG_Icon-01.png'
import JPG from '../../../../assets/png/JPG_Icon.png'
import CAD from '../../../../assets/png/AUTOCAD_Icon-01.png'
import THREE from '../../../../assets/png/3D_Icon-01.png'
import VIDEO from '../../../../assets/png/Video_Icon-01.png'

const Blob: FC<X> = ({ className }) => {
    const [currentTag, setCurrentTag] = useState<string>('');
    const [tags, setTags] = useState<string[]>([]);
    const [icon, setIcon] = useState<string>(PDF);
    const [hidden, setHidden] = useState<string>('hidden');

    const handleAddTag = (e: any) => {
        e.preventDefault();
        setTags([...tags, `#${currentTag}`]);
        setCurrentTag('');
    }

    const iconTypes = [
        {
            name: 'PDF',
            icon: PDF
        },
        {
            name: 'PNG',
            icon: PNG
        },
        {
            name: 'JPG',
            icon: JPG
        },
        {
            name: 'CAD',
            icon: CAD
        },
        {
            name: '3D',
            icon: THREE
        },
        {
            name: 'VIDEO',
            icon: VIDEO
        }
    ]

    return (
        <div className={className}>
            <div className='left'>
                <h1>Create A New Blob</h1>
                <div className='asset'>
                    <h3>Choose Icon</h3>
                    <img src={icon} onClick={() => {hidden == 'hidden' ? setHidden('') : setHidden('hidden')}} />
                    <div className={`icon-list ${hidden}`}>
                        {iconTypes.map((icon, index) => {
                            return (
                                <img src={icon.icon} key={index} onClick={() => { 
                                    setIcon(icon.icon)
                                    setHidden('hidden')
                                }} />
                            )
                        })}
                    </div>
                </div>
                <div className='upload'>
                    <h3>Upload Asset</h3>
                    <CloudUpload />
                </div>
            </div>
            <form>
                <label>Name</label>
                <input placeholder='Enter Your Item Name Here' />
                <label>Description</label>
                <input className='description' />
                <label>Custom Field 1</label>
                <input placeholder='Enter Custom Field 1 Here' />
                <label>Custom Field 2</label>
                <input placeholder='Enter Custom Field 2 Here' />
                <label>Tags</label>
                <input placeholder='Enter Tags Here' value={currentTag} onChange={(e) => setCurrentTag(e.target.value)} />
                <button onClick={(e) => handleAddTag(e)}>Add Tag</button>
                <button>Create</button>
            </form>
            <div className='tag-box'>
                <h3>added tags</h3>
                <div>
                    {tags.map((tag, index) => {
                        return (
                            <div className='tag' key={index}>
                                {tag}
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default styled(Blob)`
    background: var(--primary) center top / cover no-repeat fixed;
    width: 70%;
    height: 80%;
    margin: auto;
    background-color: red;
    border-radius: 1rem;
    background-color: var(--tertiary);
    filter: drop-shadow(10px 10px 10px rgba(0, 0, 0, 0.25));
    display: flex;
    flex-direction: row;

    .left {
        display: flex;
        flex-direction: column;
        width: 100%;
        text-align: center;
        justify-content: center;
        height: 80%;
        align-items: center;

        & h1 {
            font-style: italic;
        }

        & .upload {
            width: 50%;
            background-color: gray;
            border-radius: 1rem;
            margin: 1rem;
            filter: drop-shadow(10px 10px 10px rgba(0, 0, 0, 0.25));
            z-index: 1;

            & svg {
                width: 80%;
                margin: 1rem;
            }

            &:hover {
                cursor: pointer;
                filter: invert();
            }
        }

        & .asset {
            width: 50%;
            background-color: gray;
            border-radius: 1rem;
            margin: 1rem;
            filter: drop-shadow(10px 10px 10px rgba(0, 0, 0, 0.25));
            z-index: 1000;

            & img {
                margin: 1rem;
                width: 80%;

                &:hover {
                    cursor: pointer;
                    transform: scale(1.1);
                }
            }

            & .icon-list.hidden {
                display: none;
            }

            & .icon-list {
                position: absolute;
                width: 30vw;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                background-color: gray;
                border-radius: 1rem;
                filter: drop-shadow(10px 10px 10px rgba(0, 0, 0, 0.25));

                & img {
                    width: 8vw;

                    &:hover {
                        transform: scale(1.1);
                        filter: grayscale();
                        cursor: pointer;
                    }
                }
            }
            
        }
    }

    .tag-box {
        width: 50%;
        height: 80%;
        background-color: gray;
        border-radius: 1rem;
        text-align: center;
        display: flex;
        flex-direction: column;
        margin: auto;
        margin-right: 1rem;
        margin-left: 1rem;

        & h3 {
            background-color: white;
            width: 70%;
            text-align: center;
            margin: 1vh auto;
            border-radius: 1rem;
        }

        & .tag {
            background-color: white;
            width: 70%;
            text-align: center;
            margin: 1vh auto;
            border-radius: 1rem;
        }
    }

    form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        height: 80%;

        & input {
            border: none;
            border-radius: 1rem;
            margin: .5rem;
        }

        & .description {
            height: 10vh;
        }

        & button {
            width: 10vw;
            border: none;
            background-color: var(--primary);
            color: white;
            border-radius: 1rem;
            margin-top: 1rem;
            filter: drop-shadow(10px 10px 10px rgba(0, 0, 0, 0.25));
        }
    }


`