import { FC } from 'react';
import styled from 'styled-components';

interface props extends X {
    children?: any;
}

const AssetInfo : FC<props> = ({ className, children }) => {
    return (
        <content className={className}>
            {children}
        </content>
    )
}

export default styled(AssetInfo)`

`