import styled from 'styled-components';

const ContainerComp: React.FC<X> = ({ className, children }) => {
	return <container className={className}>{children}</container>;
};

// main page container
const Container = styled(ContainerComp)`
	position: relative;
	/* top: var(--header-height); // shift past header */
	display: flex;
	flex-direction: column;
	justify-content: start;
	align-items: center;
	/* height: calc(100vh - var(--header-height)); */
	height: 100%;
	width: 100%;

	background-image: linear-gradient(to right bottom, #ffffff52, rgba(255, 255, 255, 0));
	background-color: black;

	// mobile adjustments
	@media screen and (max-width: 480px) {
		height: unset;
	}
`;

export default Container;
