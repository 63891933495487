import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import Button from '../../GlobalShell/utils/Button';
import { Link } from 'react-router-dom';

const bookBg = require('../../assets/book.jpg');


interface props {
	className?: string;
}

const LoginForm: FC<props> = ({ className }) => {

	const [rightPanelActive, setRightPanelActive] = useState("right-panel-active");

	const handlePanelSwap = () => {
		rightPanelActive == "right-panel-active" ? setRightPanelActive("") : setRightPanelActive("right-panel-active") ;
	}

	return (
		<div className={className}>
			<div className={`container `+ rightPanelActive}>
				<div className="container-login container-form">
					<form>
						<label>Username</label>
						<input
							type="text"
							placeholder="Enter Username"
						/>

						<label>Password</label>
						<input
							type="password"
							placeholder="Enter password"
						/>
						<Link to="/dashboard">
						<Button
							type="fill"
							color="purple"
							link="/dashboard"
						>
							Login
						</Button>
						</Link>
					</form>
				</div>



				<div className="container-signup container-form">
					<form>
						<label>First Name</label>
						<input
							type="text"
							placeholder="Enter Your First Name"
						/>

						<label>Last Name</label>
						<input
							type="text"
							placeholder="Enter First Name"
						/>
						<label>Username</label>
						<input
							type="text"
							placeholder="Enter Username"
						/>
						<label>Password</label>
						<input
							type="password"
							placeholder="Enter Password"
						/>
						<label>Access Code</label>
						<input
							type="password"
							placeholder="Enter Your Company's Access Code"
						/>
						<Link to="/dashboard">
						<Button
							type="fill"
							color="purple"
							link="/dashboard"
						>
							Sign Up
						</Button>
						</Link>
					</form>
				</div>

				<div className="container-overlay">
					<div className="overlay">
						<span className="close">&times;</span>
						<div className="overlay-panel overlay-left">
							<button onClick={handlePanelSwap}>Sign In</button>
						</div>
						<div className="overlay-panel overlay-right">
							<button onClick={handlePanelSwap}>Sign Up</button>
						</div>
					</div>
				</div>

			</div>
			
		</div>
	);
};

export default styled(LoginForm)`
	margin: auto;
	background-image: linear-gradient(to right bottom, #ffffff52, rgba(255, 255, 255, 0));
	backdrop-filter: blur(10px);
	box-shadow: rgba(30, 30, 30, 0.2) 10px 10px 10px;
	width: 70vw;
	height: 75vh;
	border-radius: 1rem;

	& label {
		color: #000000;
		font-size: 1.5rem;
		margin-top: 1.5rem;
		
	}

	& input {
		background-image: linear-gradient(to right bottom, #ffffff52, rgba(255, 255, 255, 0));
		border-radius: 1em;
		padding: 1rem;
		width: 75%;
		border: none;
	}

	& button {
		background: #ffffffbe;
		margin: 1rem;
	}

	& form {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		height: 100%;
	}

	.container {
		/* background-color: darkorange; */
		background-image: linear-gradient(to right bottom, #ffffff52, rgba(255, 255, 255, 0));
		border-radius: 1em;
		height: 100%;
		max-width: 100%;
		overflow: hidden;
		position: relative;
	}

	.container-form {
		left: 0;
		position: absolute;
		top: 0;
		transition: all 0.6s ease-in-out;
	}

	.container-login {
		left: 0;
		width: 50%;
		z-index: 2;
		/* opacity: 0; */
	}

	.container.right-panel-active .container-login {
		transform: translateX(-100%);
		z-index: 100;
	}

	.container-signup {
		left: 0;
		opacity: 0;
		width: 50%;
		z-index: 2;
	}

	.container.right-panel-active .container-signup {
		animation: show 0.6s;
		opacity: 1;
		transform: translateX(100%);
		z-index: 5;
	}

	.container-overlay {
		height: 100%;
		left: 50%;
		overflow: hidden;
		position: absolute;
		top: 0;
		transition: transform 0.6s ease-in-out;
		width: 50%;
		z-index: 100;
		

		& button {
			border: none;
			color: black;
			background-color: white;
			width: 20%;
			height: 8%;
			border-radius: 1em;
			font-weight: 600;
		}
	}

	.container.right-panel-active .container-overlay {
		transform: translateX(-100%);
	}

	.overlay {
		/* background-color: blue; */
		background-image: url(${bookBg});
		background-attachment: fixed;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		height: 100%;
		left: -100%;
		position: absolute;
		transform: translateX(0);
		transition: transform 0.6s ease-in-out;
		width: 200%;
		
	}

	.container.right-panel-active .overlay {
		transform: translateX(50%);
	}

	.overlay-panel {
		align-items: center;
		display: flex;
		flex-direction: column;
		height: 100%;
		justify-content: center;
		position: absolute;
		text-align: center;
		

		top: 0;
		transform: translateX(0);
		transition: transform 0.6s ease-in-out;
		width: 50%;
	}

	.overlay-left {
		transform: translateX(-20%);
		
	}

	.container.right-panel-active .overlay-left {
		transform: translateX(0);
		
	}

	.overlay-right {
		right: 0;
		transform: translateX(0);
		
	}

	.container.right-panel-active .overlay-right {
		transform: translateX(20%);
    
	}

	@keyframes show {
		0%,
		49.99% {
			opacity: 0;
			z-index: 1;
		}
		50%,
		100% {
			opacity: 1;
			z-index: 5;
		}
	}

	@media screen and (max-width: 480px) {
		display: none;
	}
`;
