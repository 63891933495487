import { FC } from 'react';
import styled from 'styled-components';
import Header from '../../../GlobalShell/Header';
import LeftSideBar from '../../II-DASHBOARD/components/LeftSideBar';

const ModuleShell: FC<X> = ({ className, children }) => {
    return (
        <module 
        id="news"
        className={className}
        >
            <content>
                <Header />
                <LeftSideBar />
                {children}
            </content>
        </module>
    )
}

export default styled(ModuleShell)`
	background: var(--primary) center top / cover no-repeat fixed;
	width: 100%;
	height: 100%;

	& content {
        display: flex;
        flex-direction: row;
		position: relative;
        height: 100%;
	}
`