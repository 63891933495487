import styled from "styled-components";
import MobileLoginForm from './mobile-page'
import LoginForm from './web-page'

const logo = require('../../assets/Doquspace_White.png')

interface props {
    className?: string;
}

const Auth: React.FC<props> = ({ className }) => {
    return (
        <section className={className}>
            <img src={logo} />
            <MobileLoginForm />
            <LoginForm />
        </section>
    )
}

export default styled(Auth)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;

    img {
        width: 20%;
        margin-top: 1em;
    }

    @media screen and (max-width: 768px) {
        img {
            width: 80%;
        }
    }
`