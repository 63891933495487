// ----------------------
// changes made here should ONLY be things required above the router and/or shell
// change global content and styling in module shell
// change page routing in module routes index
// - TT
// ----------------------

import QRLRouter from './QRLRoutes';
import QRLShell from './QRLShell';
import Header from '../../GlobalShell/Header'

const QRL = () => {
	return (
	  <QRLShell>
		{(buildingSelected: string, machineSelected: string) => (
		  <>
			<Header />
			<QRLRouter buildingSelected={buildingSelected} machineSelected={machineSelected} />
		  </>
		)}
	  </QRLShell>
	);
  };

export { QRL };
