import { FC, useEffect, useState} from 'react';
import styled from 'styled-components';
import { getResults } from '../III-QRL/controllers';

interface props {
	className?: string;
}

type Result = {
    answers: [],
    _id: string
}

const AnalyticsPage: FC<props> = ({ className }) => {
    const [results, setResults] = useState<Result[]>();
    useEffect(() => {
        const returnResults = async () => {
            try {
                const res = await getResults();
                console.log(res);
                setResults(res);
            } catch (err) {
                console.error({ error: err })
            }
        }
        returnResults();
    },[])
	return (
		<section className={`Analytics ${className}`}>
			{results?.map(el => {
                return (
                <div>
                <h3>{el._id}</h3>
                <div className="container">
                <ul>
                    {el.answers.map(a => {
                        return (
                            
                            <li>{a}</li>
                            
                        )
                    })}
                </ul>
                </div>
                </div>
                )
            })}
		</section>
	);
};

export default styled(AnalyticsPage)`
	background: #646464;
	width: 100%;
	text-align: center;
	padding: 1rem;

	& img {
		border-radius: 50%;
		width: 30vh;
	}

    h3 {
        color: #e4e4e4;
    }

    .container {
        background-color: #e4e4e4;
        border-radius: 10px;
    }
`;
