import styled from 'styled-components';

interface P extends X {
	post: PostProps;
}

const Post: React.FC<P> = ({ className, post }) => {
	const placeholder = 'https://picsum.photos/400/400';
	return (
		<div className={className}>
			<header>
				<h2>{post.blobName}</h2>

				<h6>{post.createdAt}</h6>
			</header>

			{/* <iframe src={`https://doqsuite.s3.amazonaws.com/${post.file}`} /> */}
			<img src={placeholder} />
		</div>
	);
};

export default styled(Post)`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	background: #fff;
	background-color: #cfcfcf;
	width: 32vw;
	text-align: center;
	border-radius: 1rem;

	& header {
		background: #cfcfcf;
		border-top-right-radius: 1rem;
		border-top-left-radius: 1rem;
		padding: 1rem;

		& h2,
		h6 {
			margin: 0;
			overflow: auto;
			font-weight: 700;
		}
	}

	& img {
		height: 35vh;
	}

	@media screen and (max-width: 480px) {
		width: 90vw;
	}
`;
