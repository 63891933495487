import { FC } from 'react';
import styled from 'styled-components';
import Header from '../../../../GlobalShell/Header'
import LeftSideBar from '../../../II-DASHBOARD/components/LeftSideBar';

import manageUsers from "../../../../assets/png/User_manager.png";
import userReports from "../../../../assets/png/User_Settings-01.png";
import assetManager from "../../../../assets/png/QRL_Icon-01.png";
import moduleBuilder from "../../../../assets/png/Tools_Icon-01.png";
import procedureBuilder from "../../../../assets/png/Settings_Icon-01.png";
import { Link } from 'react-router-dom';

const AdminHome: FC<X> = ({ className }) => {
    const adminTools = [
        {
            name: "Manage Users",
            icon: manageUsers,
            link: "users",
        },
        {
            name: "User Reports",
            icon: userReports,
            link: "reports",
        },
        {
            name: "Asset Manager",
            icon: assetManager,
            link: "QRL"
        },
        {
            name: "Module Builder",
            icon: moduleBuilder,
            link: "modules"
        },
        {
            name: "Procedure Builder",
            icon: procedureBuilder,
            link: "procedures"
        },
    ]
    return (
        <admin className={className}>
            <div className="module-list">
                {adminTools.map((tool, index) => {
                    return (
                        <Link to={tool.link} key={index}>
                        <div className="single-module">
                            <img src={tool.icon} alt="icon" />
                            
                            <p>{tool.name}</p>
                        </div>
                        </Link>
                    )
                })}
            </div>
        </admin>
    )
}

export default styled(AdminHome)`
    display: flex;
    flex-direction: row;

    .module-list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        margin: 10vh auto;
        background-color: var(--primary-light);
        border-radius: 1rem;
        filter: drop-shadow(10px 10px 10px rgba(0, 0, 0, 0.25));
        width: 90%;
        text-align: center;
        color: white;

        & .single-module {
            margin: 1rem;
            filter: drop-shadow(10px 10px 10px rgba(0, 0, 0, 0.25));
            color: white;

            & p {
                text-decoration: none;

            }
        }

        & .single-module:hover {
            transform: scale(1.05);
            cursor: pointer;
        }

        & img {
            width: 25vh;
            height: 15%;
        }
    }

    @media screen and (max-width: 408px) {
        margin-top: 18vh;

        .module-list {

            & img {
                width: 15vh;
                height: 15%;
            }
        }


    }
`