import styled from "styled-components";

interface props {
    className?: string;
    selected: boolean;
}

const profileImage = require('../../assets/profile.png')

const Notes = ({ className, selected }: props) => {
    return (
        <div className={className}>
            { selected ?
            <div className="notes">
                <div className="nav">
                <h1>Shift Notes</h1>
                <h4>General Notes </h4>
                <h4>Electrical Notes </h4>
                <h4>Mechanical Notes </h4>
                </div>
                <div className="notes-container">
                    <div className="element">
                        <img className={profileImage} src={profileImage} />
                        <div className="text">
                            <h2>Mike McCanney</h2>
                            <p className="desc">Posted Date: 10/11/2023</p>
                            <p className="desc">Located in: Mechanical Notes</p>

                            <p>Clicking noise in machine fixed, problem was lack of PM, tech greased the motor to eliminate.</p>
                        </div>
                    </div>

                    <div className="element">
                        <img className={profileImage} src={profileImage} />
                        <div className="text">
                            <h2>Mike McCanney</h2>
                            <p className="desc">Posted Date: 10/11/2023</p>
                            <p className="desc">Located in: General Notes</p>

                            <p>Updates being added to Air Handling Unit in two weeks</p>
                        </div>
                    </div>
                </div>
            </div>
            : null }
        </div>
    )
}

export default styled(Notes)`

    .notes {
        display: flex;
        flex-direction: column;
        align-items: left;
        margin-left: 2em;

        & h1 {
            color: white;
        }
    }

    .nav {
        color: white;
        align-items: center;

        & h4 {
            margin-left: 1em;
            margin-right: 1em;
            font-style: italic;
            color: orange;
        }

        & h4:hover {
            cursor: pointer;
            color: white;
        }
    }

    .notes-container {
        display: flex;
        flex-direction: column;
        align-items: left;
        background-color: white;
        border-radius: 1em;
        padding: 1em;
        width: 50vw;

        & .element {
            display: flex;
            flex-direction: row;
            left: 0;
            align-items: left;
            background-color: white;
            border-radius: 1em;
            padding: 1em;

            & img {
                width: 10vw;
                height: 10vw;
            }
            

            & .profile {
                display: block;
                background-color: green;
                width: 10em;
                height: 9em;
                border-radius: 50%;
                object-fit: cover;
            }
            
            .text {
                width: 100%;
                height: 10em;
                margin-left: 1em;
            }

            & .desc {
                font-style: italic;
                opacity: 60%;
            }
        }


    }
    
`;