import { FC } from 'react';
import styled from 'styled-components';

const ModuleShell: FC<X> = ({ className, children }) => {
    return (
        <module 
        id="admin"
        className={className}
        >
            <content>
                {children}
            </content>
        </module>
    )
}

export default styled(ModuleShell)`
	background: var(--primary);
	width: 100%;
	height: 100vh;

	& content {
		position: relative;
        display: flex;
        flex-direction: row;
        height: 100vh;
    }
`