import styled from 'styled-components';

import LeftSideBar from './components/LeftSideBar';
import WidgetBar from './components/WidgetBar';
import Header from '../../GlobalShell/Header';
import NewsFeed from './components/NewsFeed';
import auth from '../X-LOGIN/controllers/auth';
import { useEffect, useState } from 'react';
import MobileAppList from './components/MobileAppList';
import SearchIcon from '../../assets/png/Q-01.png';
import { Link } from 'react-router-dom';

import UserSettings from '../../assets/png/User_Settings-01.png';
import UserManager from '../../assets/png/User_manager.png';
import Tools from '../../assets/png/Tools_Icon-01.png';
import Notes from '../../assets/png/Notes_Icon-01.png';
import QRL from '../../assets/png/QRL_Icon-01.png';
import Quiz from '../../assets/png/Quiz.png';

const Dashboard: React.FC<{ className?: string }> = ({ className }) => {
	// const loggedIn = auth.loggedIn();
	const loggedIn = true;
	const [selected, setSelected] = useState("");

	const activeNavOptions = [
		"Laser Table",
		"WallE",
	];

	const appList = [
		{
			src: QRL,
			name: "QRL",
			link: "/qrl",
		},
		{
			src: Quiz,
			name: "Course Viewer",
			link: "/quiz/lasertable"
		},
		{
			src: UserSettings,
			name: "User Settings",
			link: "/dashboard"
		},
	]

	
	useEffect(() => {
		if (loggedIn) {
			console.log(loggedIn);
			console.log(auth.getUsername());
		}
	})

	const spliceSpacesConvertToLowerCase = (str: string) => {
		let newStr = str.split(" ").join("").toLowerCase();
		return newStr;
	};

	return (
		<>
		{loggedIn ? 
			<div className={`dashboard ${className}`}>
				<Header />
				<LeftSideBar>
				<div className="QRL-list">
						{/* <button className="QRL-drop">
							<img className="search" src={SearchIcon} /><input placeholder='Search for a Procedure' />
						</button>		 */}
						<div className="quick-nav">
						{activeNavOptions.map((option, i) => {
							return (
								<a href={`/QRL/${spliceSpacesConvertToLowerCase(option)}`}>
								<div onClick={() => {
									setSelected(option);
								}} className={option == selected ? "supercollection selected" : "supercollection"} key={i}>
									<p>{option}</p>
								</div>
								</a>
							);
						  })}
						</div>

						<h4 className="module-header">modules</h4>
						<div className="app-list">
							{appList.map((option, i) => {
								return (
									<Link to={option.link}>
									<div className="app" key={i}>
										<img src={option.src} />
										<p>{option.name}</p>
									</div>
									</Link>
								);
							})}
						</div>

					</div>
				</LeftSideBar>
				<NewsFeed />
			
				<MobileAppList />
			</div>
		: null }		
		</>
	);
};

export default styled(Dashboard)`
	display: flex;
	/* width: 100%; */
	/* height: 100%; */
	background-color: var(--primary);

	h2 {
		color: #ffffff;
		font-weight: 600;
		opacity: 70%;
	}

	.module-header {
		color: #1a1a1a;
		font-weight: 600;
		font-style: oblique;
		border-bottom: 2px #1a1a1a solid;
		border-radius: 1rem;
		padding-left: 1rem;
		padding-right: 1rem;
		width: 100%;
		/* opacity: 70%; */
	}

	.quick-nav {
		width: 100%;
		border-radius: 1rem;
	}

	& .app-list {
		/* display: flex; */
		flex-direction: column;
		justify-content: center;
		flex-wrap: wrap;
		align-items: center;
		
		width: 100%;

		& a {
			width: 100%;
			font-weight: 700;
			font-size: larger;
			color: white;
			text-decoration: none;
		}

		& .app {
			/* display: flex; */
			/* flex-direction: column; */
			/* justify-content: center; */
			align-items: center;
			/* margin: .5rem; */
			border-radius: 1rem;
			filter: drop-shadow(5px 5px 4px rgba(0, 0, 0, 0.5));
			justify-content: center;
			align-items: center;
			width: 100%;
			/* padding: 1rem; */
			margin-top: .3rem;
			

			& a {
				width: 20vw;
			}
			& p {
				color: black;
				text-decoration: none;
				background-color: white;
				padding: 1rem;
				border-radius: 1rem;

			}
			& img {
				display: none;
				filter: grayscale(100%);
			}
		}
	}





	& .QRL-list {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100%;
	}

	& a {
		text-decoration: none;
	}

	& .supercollection {
		background-color: white;
		border: none;
		margin: .5rem;
		border-radius: 1rem;
		filter: drop-shadow(5px 5px 4px rgba(0, 0, 0, 0.5));
		justify-content: center;
		align-items: center;
		& p {
			color: black;
			text-decoration: none;
		}
	}

	& .selected {
		background-color: var(--secondary);
	}

	& .QRL-drop {
		border: none;
		margin: .5rem;
		border-radius: 1rem;
		filter: drop-shadow(5px 5px 4px rgba(0, 0, 0, 0.5));
		background-color: white;
		color: var(--primary);
		font-weight: 500;
		align-content: center;
		display: flex;
		align-items: center;

		& img {
			width: 1.5rem;
			margin: 0;
		}
		& input {
			border: none;
			border-radius: 0 1rem 1rem 0;
			height: 1.5rem;
			margin: .5rem 0;
		}
	}

	& button:hover {
		transform: scale(1.01);
	}

	@media screen and (max-width: 480px) {
		display: block;
	}
`;
