import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import styled from 'styled-components';

import NewsHome from './Home/page'

const NewsRouter: FC<X> = ({ className }) => {
    return (
        <Routes>
            <Route
                path="/"
                element={<NewsHome />}
            />
        </Routes>
    )
}

export default NewsRouter;