import styled from 'styled-components';

import GlobalStyles from './GlobalStyles';
import Container from './Container';
import Footer from './Footer';
import Main from './Main';

const Shell: React.FC<X> = ({ className, children }) => {
	return (
		<>
			<GlobalStyles />
			<Main>
				<Container>{children}</Container>
				<Footer />
			</Main>
		</>
	);
};

export default styled(Shell)`
	background-color: black;
`;
