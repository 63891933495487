import styled from "styled-components";
import Button from "../utils/Button";

interface props {
    className?: string;
    selected: boolean;
}

const Support = ({ className, selected }: props) => {
    return (
        <div className={className}>
            { selected ?
            <div className="container">
            <h1>Support</h1>

            <h3>Message Admin</h3>
            <textarea placeholder="Enter your message here..."></textarea>
            <Button type="fill" color="purple">Send Message</Button>


            <div>
                <p>Contact ITS: (810) 714-7630</p>
                <p>118 South Leroy St. Fenton, MI 48340</p>
            </div>
            </div>
            : null }
        </div>
    )
}

export default styled(Support)`

    .container {
        display: flex;
        flex-direction: column;
        align-items: right;
    }

    h1 {
        color: white;
    }

    h3 {
        left: 0;
    }

    p {
        color: white;
        font-style: italic;
    }

    textarea {
        width: 40vw;
        height: 10vw;
    }
`;