
import AdminRouter from './AdminRoutes';
import AdminShell from './AdminShell';
import Header from '../../GlobalShell/Header'
import LeftSideBar from '../II-DASHBOARD/components/LeftSideBar';
import { FaArrowAltCircleDown, FaSearch } from 'react-icons/fa';
import Button from '../../GlobalShell/utils/Button';
import SearchBar from '../III-QRL/QRLShell/components/SearchBar';

const Admin = () => {
    return (
        <AdminShell>
            <Header />
            <LeftSideBar>
                <Button link="none" type="fill" color={`var(--secondary)`}>
                    Admin Dashboard
                    <FaArrowAltCircleDown size={30} />
                </Button>

                {window.location.pathname.includes("users") ?
                <div>
                {/* <SearchBar /> */}
                <FaSearch />
                <input style={{ borderRadius: "1rem", border: "none" }}></input>
                <Button link="none" type="fill" color={`var(--secondary)`}>Group 1</Button>
                <Button link="none" type="fill" color={`var(--secondary)`}>Group 2</Button>
                </div>
            : null }
            </LeftSideBar>
            <AdminRouter />
        </AdminShell>
    )
}

export { Admin }