import { useState, useEffect } from 'react';
import { getBlobsByName, getBlobs } from '../../../controllers';

const HandlePostFetch = () => {
	const [posts, setPosts] = useState<PostProps[]>([]);

	useEffect(() => {
		const blob = localStorage.getItem('selected_robot');

		// fetch blobs related to robot from quiz selection
		if (blob) {
			getBlobsByName(blob).then((data: any) => {
				setPosts(data);
			});
		} else {
			// if array is empty fetch standard return
			getBlobs(1, 10).then((data: any) => {
				setPosts(data);
			});
		}
	}, []);

	return { posts };
};

export { HandlePostFetch };
