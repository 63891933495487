import { FC, useEffect } from 'react';
import styled from 'styled-components';

interface props {
	className?: string;
}

const SettingsPage: FC<props> = ({ className }) => {
	return (
		<section className={`Settings ${className}`}>
			
		</section>
	);
};

export default styled(SettingsPage)`
	background: var(--primary);
	width: 100%;
	height: 100vh;
	text-align: center;
	padding: 1rem;

	& img {
		border-radius: 50%;
		width: 30vh;
	}
`;