import { FC, useState } from 'react';
import styled from 'styled-components';

import pdfIcon from '../../../../assets/png/PDF_Icon-01.png'
import folderIcon from '../../../../assets/png/Asset_1.png'
// import machineImage from '../../../../assets/machines/machine2.png'
import { FaArrowAltCircleDown, FaArrowLeft } from 'react-icons/fa';
import { ReactComponent as CloudUpload } from '../../../../assets/tool_icons/Cloud_Upload_Black.svg'
import { Link } from 'react-router-dom';
import { ReactComponent as SearchIcon } from '../../../../assets/tool_icons/SearchBar.svg';

import { FaDownload, FaRegEye, FaCogs, FaTrash } from 'react-icons/fa';
import { SlSpeech } from 'react-icons/sl';

import hillberryPhoto from '../../../../assets/hillberry.png'
import { FaSadTear } from 'react-icons/fa';

import SearchBar from '../../QRLShell/components/SearchBar';
import Notes from '../Home/components/Notes';
import AssetInfo from '../Home/components/AssetInfo';

import profileImage from '../../../../assets/profile.png'

import { siemens } from '../../../../assets';

interface props extends X {
	buildingSelected?: string;
}

const Folder: FC<props> = ({ className, buildingSelected }) => {

	const [notesVisible, setNotesVisible] = useState("hidden")
	const [tableVisible, setTableVisible] = useState("")

	const noteElements = [
        {
            creator: "Cassandra Franklin",
            datePosted: "11/15/23",
			body: "Careful sewing these!"
        },
		{
            creator: "Cassandra Franklin",
            datePosted: "11/15/23",
			body: "I ran into an issue with this project last week..."
        },
    ]

	const blobs: any[] = [
		{
			technology: "Laser Table",
			operation: "MOLLE ATTACHMENTS",
			assetType: "Work Instruction",
			name: "Front Attachment",
		},
		{
			technology: "Laser Table",
			operation: "MOLLE ATTACHMENTS",
			assetType: "Work Instruction",
			name: "Back Attachment",
		},
		{
			technology: "Laser Table",
			operation: "MOLLE ATTACHMENTS",
			assetType: "Work Instruction",
			name: "Left Attachment",
		},
		{
			technology: "Laser Table",
			operation: "MOLLE ATTACHMENTS",
			assetType: "Work Instruction",
			name: "Right Attachment",
		},
		{
			technology: "Laser Table",
			operation: "MOLLE ATTACHMENTS",
			assetType: "Work Instruction",
			name: "Forward Attachment",
		},
		{
			technology: "Laser Table",
			operation: "MOLLE ATTACHMENTS",
			assetType: "Work Instruction",
			name: "Laser Attachment",
		},
	];

	const openPDF = (src: any) => {
		const pdfURL = process.env.PUBLIC_URL + src;
		window.open(pdfURL, '_blank');
	}

    return (
        <folder className={className}>
			<div className="back"><a href="/QRL"><FaArrowLeft size={30} color={"white"} /></a></div>
            <div className="filter">
				<button onClick={() => {
					setNotesVisible(notesVisible == "hidden" ? "" : "hidden")
					setTableVisible(tableVisible == "" ? "hidden" : "")
				}}>Info / Notes</button>
				<div className="crumb-container">
					<p>You are in Technology: <span>Sewing</span> and Project: <span>R3DS</span></p>
				</div>
				{/* {true && (
					<Link to="/create/blob">
					<button>
						Upload
						<CloudUpload />
					</button>
					</Link>
				)} */}

			</div>
            <div className="blob-list">
				<div className="crumb-container-mobile">
					<p>You are in technology: <span>Sewing </span></p>
					<p>and project: <span>R3DS</span></p>
					<button onClick={() => { 
					setNotesVisible(notesVisible == "hidden" ? "" : "hidden")
					setTableVisible(tableVisible == "" ? "hidden" : "")
						}}>About / Info</button>
				</div>
				<div className={`mobile-about ${notesVisible}`}>
					<Notes>
						{noteElements.map((note, i) => {
							return (
								<div className="note">
									<h2><span><img src={profileImage} /></span>{note.creator}</h2>
									<em>{note.datePosted}</em>
									<p>{note.body}</p>
								</div>
								)
							})
						}
					</Notes>
					<AssetInfo>
						<img src={siemens[0]}/>
						<div>
							<h2>R3DS</h2>
							<p>Sewing Project</p>
						</div>
					</AssetInfo>
				</div>
            {blobs.map((blob, index) => {
                return (
                    <div className="blob" key={index}>
                        <img src={pdfIcon} onClick={() => openPDF(blob.src)} />
                        {blob.name}
                    </div>
                )
            })}
            </div>
            <div className={`blob-table ${tableVisible}`}>
				<table>
					<tr>
						<th>Technology</th>
						<th>Project</th>
						<th>Operation</th>
						<th>Name</th>
						{/* <th>Manufacturer</th>
						<th>Floor #</th> */}
						<th>Type</th>
						<th>Asset</th>
						<th>Notes</th>
						<th>Date</th>
						{/* <th>Actions</th> */}
					</tr>
					<tr className="search-bars">
						<th><SearchIcon /><input placeholder='Search' /></th>
						<th><SearchIcon /><input placeholder='Search' /></th>
						{/* <th><SearchIcon /><input placeholder='Search by Manufacturer' /></th>
						<th><SearchIcon /><input placeholder='Search by Floor' /></th> */}
						<th><SearchIcon /><input placeholder='Search' /></th>
						<th><SearchIcon /><input placeholder='Search'/></th>
						<th><SearchIcon /><input placeholder='Search'/></th>
						<th></th>
						<th></th>
						<th></th>
					</tr>
					{blobs.map((blob, index) => {
						return (
							
							<tr key={index}>
								<td className="asset-link"><Link to="/QRL/blob">{blob.technology}</Link></td>
								<td>R3DS</td>
								<td>{blob.operation}</td>
								<td>{blob.name}</td>
								<td>{blob.assetType}</td>
								{/* <td>{blob.manufacturer}</td>
								<td>{blob.floor}</td> */}
								<td><img src={pdfIcon} onClick={() => openPDF(blob.src)} /> PDF</td>
								<td><SlSpeech /></td>
								{/* <td className="settings">
									QR 
									<FaRegEye size={20} />
									<FaDownload size={20} />
									<FaCogs size={20} />
									<FaTrash size={20} />
								</td> */}
								<td>10/27/2023</td>
							</tr>
						)
					})}
				</table>
			</div>
			<div className={`about-container ${notesVisible}`}>
				<Notes>
					<h2>Notes</h2>
					{noteElements.map((note, i) => {
						return (
							<div className="note">
								<h2><span><img src={profileImage} /></span>{note.creator}</h2>
								<em>{note.datePosted}</em>
								<p>{note.body}</p>
							</div>
							)
						})
					}
				</Notes>
				<AssetInfo>
					<img src={siemens[0]}/>
					<div>
						<h2>R3DS</h2>
						<em>Sewing</em>
					</div>
				</AssetInfo>
			</div>
        </folder>
    )
}

export default styled(Folder)`
	display: flex;
	flex-wrap: nowrap;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	overflow: auto;

	& .back {
		display: none;
	}
	

	.about-container {
		width: 95%;
		height: 80vh;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-content: center;
		background: #fff;
		/* margin-top: 20vh; */
		
		border-radius: 1rem 1rem 1rem 1rem;
		filter: drop-shadow(10px 10px 10px rgba(0, 0, 0, 0.5));

		& content {
			
			display: flex;
			flex-direction: column;
			background-color: var(--primary-light);
			margin: 1rem;
			padding: 1rem;
			border-radius: 1rem;
			justify-content: center;
			align-items: center;
			height: 75vh;
			width: 50%;

			ul {
				& p {
					border-radius: .5rem;
					font-weight: 500;
				}
			}

			& em {
				background-color: var(--secondary-light);
				padding: .2rem;
				border-radius: .5rem;
				font-weight: 500;
			}

			& img {
					width: 60%;
					margin-bottom: 2rem;
					border-radius: 1rem;
			}

			& div {
				background-color: #fff;
				padding: 1rem;
				border-radius: 1rem;
				margin: .2rem;
				width: 80%;
			}
		}

		& .note {
			max-height: 30vh;
			overflow-y: scroll;
			& img {
				border-radius: 50%;
				width: 4rem;
				filter: drop-shadow(5px 5px 4px rgba(0, 0, 0, 0.5));
				margin-right: 1rem;
			}
		}
	}

	.about-container.hidden {
		display: none;
	}

	.blob-table.hidden {
		display: none;
	}



	.search-bars {
		align-items: center;
		margin: auto;

		input {
			border: none;
			border-radius: 0 1rem 1rem 0;
			height: 1.5rem;
			margin: .5rem 0;
			width: 80%;
		}

		svg {
			/* width: 4.5%; */
			padding: .2rem;
			height: 1.5rem;
			margin: .5rem 0;
		}
	}

	.filter {
		margin-top: 5vh;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-around;
		width: 100%;

		& button {
			border: none;
			border-radius: 1rem;
			margin: .5rem;
			padding: .5rem;
			width: 12rem;

			& svg {
				max-width: 2rem;
				margin-left: 1rem;
			}
		}

		& p {
			color: black;
			font-weight: 500;
			margin-left: 1rem;
			background-color: var(--secondary);
			padding: .5rem;
			border-radius: 1rem;
		}

		& .greeting {
			margin-top: 1rem;
			margin-right: 1rem;
			& p {
				background-color: var(--secondary);
			}
			
		}

		& .crumb-container {
			padding: .5rem;
			border-radius: 1rem;
			margin-top: 1rem;

			& span {
				background-color: var(--tertiary);
				border-radius: 1rem;
				padding: .2rem;
			}
		}
	}

	.blob-list {
		display: none;
	}

	.settings {
		& svg {
			margin-left: .5rem;
		}	
	}

	.blob-table {
		width: 96%;
		max-height: 80vh;
		overflow-y: scroll;
		display: flex;
		flex-direction: column;
		/* justify-content: center; */
		align-content: center;
		background: #fff;
		/* margin-top: 20vh; */
		border-radius: 1rem 1rem 1rem 1rem;
		filter: drop-shadow(10px 10px 10px rgba(0, 0, 0, 0.5));
	}

	table {
		max-width: 100%;
		height: 80vh;
		align-items: center;
		border-collapse: collapse;
		table-layout: fixed;
		& th {
			max-height: 5vh;
			text-align: left;
			text-align: left;
			word-wrap: break-word;
		}

		& tr {
			max-height: 5vh;
		}

		& tr:nth-child(even) {
			background-color: var(--primary-light);
		}

		& td {
			padding: 1rem;
			text-align: left;
			word-wrap: break-word;
		}

		& tr:hover {
			cursor: pointer;
			background-color: var(--secondary);
		}

		& button {
			max-height: 5vh;
		}

		& img {
			width: 4rem;
		}

		& img:hover {
			cursor: pointer;
		}

		& button {
			border: none;
			border-radius: 1rem;
			padding-left: 1rem;
			padding-right: 1rem;
			text-align: center;
			filter: drop-shadow(10px 10px 10px rgba(0, 0, 0, 0.5));
		}

		& .asset-link {
			& a {
				color: black;
				text-decoration: none;
			}
		}
	}

	.crumb-container-mobile {
			display: none;
	}

	@media screen and (max-width: 1300px) {
		& .filter {
			margin-top: 5vh;
			width: 100%;

			& button {
				font-size: small;
			}

			& p {
				font-size: x-small;
			}
		}

		.folder-table {
			width: 100%;
			
		}
		th, td {
			font-size: small;
		}
	}

	@media screen and (max-width: 1086px) {
		& .filter {
			margin-top: 5vh;

			& p {
				font-size: x-small;
				margin: 0;
			}

			& button {
				font-size: x-small;
			}
		}
	
	}

	@media screen and (max-width: 480px) {

		& .about-container {
			display: none;
		}

		& .about-container {
			display: none;
		}

		& .back {
			position: absolute;
			z-index: 1000;
			display: block;
			top: 1rem;
			left: 1.5rem;
		}

		.folder-list {
			margin: auto;
			margin-top: 27vh;
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: center;

			& button {
				border: none;
				border-radius: 1rem;
				padding: .5rem;
			}

			& img {
				width: 100%;
			}
		}

		& .mobile-about {
			display: flex;
			background-color: white;
			margin: 1rem;
			border-radius: 1rem;
			padding: 1rem;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			& img {
				width: 80%;
				margin-bottom: 2rem;
				border-radius: 1rem;
			}

			& .note {
				width: 100%;
				height: 20em;
				overflow: auto;
				display: flex;
				flex-direction: column;

				& img {
					width: 30%;
					border-radius: 50%;
				}
			}
		}

		& .mobile-about.hidden {
			display: none;
		}

		& .folder.hidden {
			display: none;
		}

		.crumb-container-mobile {
			display: block;
			padding: .5rem;
			border-radius: 1rem;
			text-align: center;

				& p {
					color: black;
					font-weight: 500;
					background-color: var(--secondary);
					padding: .5rem;
					border-radius: 1rem;
				}

				& span {
					background-color: var(--tertiary);
					border-radius: 1rem;
					padding: .2rem;
				}

				& button {
					border: none;
					border-radius: 1rem;
					padding: .5rem;
				}

			}
		
		.blob-list {
			margin: auto;
			margin-top: 27vh;
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: center;
		}

		& .blob {
			background-color: gray;
			border-radius: 1rem;
			margin: .2rem;
			width: 43vw;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			font-style: normal;
			font-weight: 600;
			text-decoration: none;
			color: black;
            overflow-x: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: small;
		}

		& img {
			width: 40vw;
			margin: auto;
			padding: .2rem;
		}

		& table {
			display: none;
		}

		& .filter {
			display: none;
		}
	}
`