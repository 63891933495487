import { FC, useState } from 'react';
import { FaExclamationTriangle, FaKeybase, FaKeycdn, FaStickyNote } from 'react-icons/fa';
import styled from 'styled-components';

interface props extends X {
    children?: any;
    stepName: string;
    workInstructions: string[];
    keyPoint: string | null;
    pictures: string[];
    safetyNote: string | null;
    index: number;
    isCompleted: boolean;
}

const Step: FC<props> = ({ className, stepName, workInstructions, keyPoint, pictures, safetyNote, index, isCompleted }) => {
    const [translateXValue, setTranslateXValue] = useState(0);

    const moveCarousel = (direction: 'prev' | 'next') => {
      const carouselWidth = document.querySelector('.carousel')?.clientWidth || 0;
      const movementAmount = direction === 'prev' ? carouselWidth : -carouselWidth;
      setTranslateXValue((prevValue: any) => prevValue + movementAmount);
    };

    return (
        <section className={`Step ${className}`}>
            <h1>Step: {stepName}</h1>
            {isCompleted ?
            <>
            </>
            :
            <div className="work-instructions">
                <div className="left">
                    <h3>Work Instructions</h3>
                    <ul>
                        {workInstructions.map(instruction => <li>{instruction}</li>)}
                    </ul>
                    {keyPoint &&
                    <h4><FaStickyNote /> {keyPoint}</h4>
                    }
                    {safetyNote &&
                    <h4 className="warning"><FaExclamationTriangle />{safetyNote}</h4>
                    }
                    <div className="checkbox">
                        <input type="radio" onClick={() => isCompleted = true} /> <label>Complete</label> <br />
                    </div>
                </div>
                <div className="carousel">
                <div className="images" style={{ transform: `translateX(${translateXValue}px)` }}>
                        {pictures.map(picture => <img src={picture} />)}
                    </div>
                    <button className="prev" onClick={() => moveCarousel('prev')}>Previous</button>
                    <button className="next" onClick={() => moveCarousel('next')}>Next</button>
                </div>
            </div>
            }
        </section>
    );
};

export default styled(Step)`
    background: white;
    width: 100%;
    height: 100%;
    margin: 1rem auto;
    border-radius: 1rem;
    border: 2px solid var(--primary);

    & .work-instructions {
        padding: 1rem;
        text-align: left;
        width: 100%;
        /* height: 100%;; */
        display: flex;
        flex-direction: row;
        justify-content: center;

        & .left {
            width: 50%;
            margin: auto;
            padding: 1rem;
        }

        /* & .images {
            display: flex;
            flex-direction: column;
            width: 40%;
            height: 100%;
            /* background-color: grey; */
            /* border-radius: 1rem;
            overflow-y: auto;
            & img {
                width: 100%;
                /* margin: .5rem; */
                /* border-radius: 1rem; */
        /* } */

        & .carousel {
            display: flex;
            overflow: hidden;
            width: 50%;
            position: relative;
            border: none;
            border-radius: 1rem;

            & button {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                border: none;
                border-radius: 1rem;
                background-color: var(--secondary);
                margin: 1rem;
            }
        }

        & .carousel .images {
            display: flex;
            transition: transform 0.3s ease-in-out;
            border: none;
            border-radius: 1rem;
        }

        & .carousel img {
            width: 100%;
            border-radius: 1rem;
            margin: 1rem;
            height: 40vh;
        }

        & .carousel .prev,
        .carousel .next {
            cursor: pointer;
            padding: 10px;
            color: #fff;
        }

        & .carousel .prev {
            margin-right: 10px;
        }

        & .carousel .next {
            margin-left: 10px;
        }

        & .carousel .prev.disabled,
        .carousel .next.disabled {
            pointer-events: none;
            opacity: 0.5;
            position: absolute; /* Position the buttons absolutely */
            top: 0; /* Position at the top */
            padding: .5rem;
            color: white;
            font-weight: 600;
            text-transform: uppercase;
            cursor: pointer;
        }

        & .carousel .prev {
            left: 0; /* Position on the left side */
            z-index: 1;
        }

        & .carousel .next {
            right: 0; /* Position on the right side */
            z-index: 1;
        }

        & .carousel .prev:hover,
        .carousel .next:hover {
            background-color: #aaa;
        }

        & h3 {
            background-color: white;
            border-radius: 1rem;
            padding: .5rem;
            width: 50%;
        }

        & h4 {
            background-color: white;
            border-radius: 1rem;
            padding: .5rem;
            width: 100%;
            margin-top: 1rem;
            border: 2px solid green;
        }

        & .warning {
            color: red;
            border: 2px solid red;
            padding: .5rem;

            & svg {
                margin-right: .5rem;
            }
        }

        & .checkbox {
            background-color: grey;
            border-radius: 1rem;
            padding: .5rem;
            color: white;
            font-weight: 600;
            text-transform: uppercase;

            & input {
                margin-right: .5rem;
                border: none;
                width: 2rem;
            }
        }
    }

    @media screen and (max-width: 756px) {
        
        & .work-instructions {
            border-radius: 1rem;
            flex-direction: column;
            width: 100%;
            padding: 0;

            & .left {
                width: 100%;
            }

            & .images {
                width: 100%;
                height: 100%;
            }

            & .carousel {
                width: 100%;
                height: 100%;
            }
        }
    }
`