
import CreateRouter from './CreateRoutes';
import CreateShell from './CreateShell';

const Create = () => {
    return (
        <CreateShell>
            <CreateRouter />
        </CreateShell>
    )
}

export { Create }