import { useState, useEffect, FC } from 'react';
import styled from 'styled-components';
import { FaBookmark, FaComment, FaEye, FaFileDownload, FaUserCircle } from 'react-icons/fa';
import { getBlobById, saveBlobToUser, createNote } from '../../../../controllers';

interface P extends X {
	post: PostProps;
}

const Comments: FC<P> = ({ className, user, post }) => {
	const [note, setNote] = useState<string>('');

	const [notes, setNotes] = useState<Note[]>([]);

	const [notePosted, setNotePosted] = useState(false);

	const [activePost, setActivePost] = useState<PostProps>();

	const [commentsActive, setCommentsActive] = useState(false);

	const toggleComments = (post: PostProps) => {
		if (commentsActive == false) {
			setCommentsActive(true);
		}
		if (activePost === post) {
			setActivePost(undefined);
		} else {
			setActivePost(post);
		}
	};

	const saveBlob = async (postId: string) => {
		const userId = localStorage.getItem('user_id');
		if (userId)
			try {
				const res = await saveBlobToUser(userId, postId);
				console.log(res);
			} catch (err) {
				console.error({ error: err });
			}
	};

	const isSaved = (postId: string): boolean => {
		const check = user?.savedBlobs.filter((s) => {
			return s == postId;
		});
		if (check && check.length > 0) {
			return true;
		} else {
			return false;
		}
	};

	const submitNote = async (post: PostProps) => {
		setCommentsActive(!commentsActive);
		setNotePosted(!notePosted);
		const userId = localStorage.getItem('user_id');
		const userName = localStorage.getItem('user_name');
		console.log(userId);
		if (userId == null || userName == null) return;
		try {
			const res = await createNote(userId, post._id, note);
			if (res.ok) setNote('');
		} catch (err) {
			console.error({ message: `failed to create note`, error: err });
		}

		const newNote: Note = {
			bodyText: note,
			userName: userName,
		};

		post.notes.push(newNote);
	};

	const x = post._id === (activePost?._id || '');

	useEffect(() => {
		if (activePost) {
			const fetchComments = async () => {
				try {
					const data: PostProps = await getBlobById(activePost._id);
					console.log(data);
					setNotes(data.notes);
				} catch (err) {
					console.error({ message: `failed to retrieve blob`, error: err });
				}
			};
			fetchComments();
		}
	}, [activePost]);

	return (
		<div className={className}>
			<div className="post-controls">
				<button>
					<FaEye size={30} />
				</button>

				<button>
					<FaComment
						size={30}
						onClick={() => toggleComments(post)}
					/>
				</button>

				<button>
					<FaFileDownload size={30} />
				</button>

				<button>
					<FaBookmark
						size={30}
						onClick={isSaved(post._id) ? () => saveBlob(post._id) : () => console.log('saved already')}
					/>
				</button>
			</div>

			<div className="comment-block">
				{/* Render notes for the active post */}
				{post.notes.length > 0 &&
					post.notes.slice(0, 2).map((note) => {
						return (
							<div
								className="comment-body"
								key={note._id}
							>
								<h5>
									<FaUserCircle />
									{note.userName}
								</h5>
								<p>{note.bodyText}</p>
							</div>
						);
					})}
				{post.notes.length > 2 && <div className="see-more-comments">See {post.notes.length - 2} more comments</div>}
				{/* Render the comment block and notes only for the active post */}
				{/* {x && commentsActive && ( */}
				<div>
					{/* <p>No one has left a note yet!</p> */}
					<div className="comment-field">
						<input
							placeholder="say something..."
							value={note}
							onChange={(e: any) => setNote(e.target.value)}
						/>
						<button onClick={() => submitNote(post)}>Submit</button>
					</div>
				</div>
				{/* )} */}
			</div>
		</div>
	);
};

export default styled(Comments)`
	& .post-controls {
		display: flex;
		justify-content: space-evenly;
		background: #cfcfcf;
		padding: 0.5rem;

		& button {
			border: none;
			border-left: solid grey 1px;
			border-right: solid grey 1px;
			background: none;
			width: 25%;
			padding: 0.5rem 0;
			transition: all 0.2s ease-in-out;

			&:hover {
				background: #79797926;
				& svg {
					transition: all 0.2s ease-in-out;
					color: pink;
				}
			}
		}
	}

	& .comment-block {
		padding-bottom: 1rem;

		& .see-more-comments {
			text-align: center;
			margin: 1rem;
			font-weight: 500;

			&:hover {
				color: blue;
				cursor: pointer;
			}
		}
		.comment-field {
			display: flex;
			justify-content: center;
			align-items: center;
			margin-bottom: 1em;

			& button {
				margin-left: 0.5em;
				background-color: #252525;
				color: #f5f5f5;
				border-radius: 10px;
				border: none;
				height: 5vh;
				padding: 0.5em;
				font-weight: 600;
			}

			& input {
				margin-right: 0.5em;
				border: none;
				background-color: #f5f5f5;
				border-radius: 1rem;
				height: 5vh;
				width: 73%;
				font-style: italic;
			}
		}

		.comment-body {
			margin: 0.5em;
			background: #ffffff;
			border-radius: 1rem;
			padding: 0.2rem 1rem;

			& h5 {
				color: #000;
				font-weight: 700;
			}
			& svg {
				padding: 0.05em;
				margin-right: 0.3em;
			}

			& p {
				margin-bottom: 0.5rem;
			}
		}
	}
`;
