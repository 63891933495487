import { FC, useState } from 'react';
import styled from 'styled-components';

import { ReactComponent as CloudUpload } from '../../../../assets/tool_icons/Cloud_Upload_Black.svg'

import PDF from '../../../../assets/png/PDF_Icon-01.png'

const Collection: FC<X> = ({ className }) => {
    const [currentTag, setCurrentTag] = useState<string>('');
    const [tags, setTags] = useState<string[]>([]);
    const [icon, setIcon] = useState<string>(PDF);
    const [hidden, setHidden] = useState<string>('hidden');

    const handleAddTag = (e: any) => {
        e.preventDefault();
        setTags([...tags, `#${currentTag}`]);
        setCurrentTag('');
    }

    const blobs = [
        {
            name: 'blob1',
            icon: PDF
        },
        {
            name: 'blob2',
            icon: PDF
        },
        {
            name: 'blob3',
            icon: PDF
        }
    ]

    return (
        <div className={className}>
            <div className='left'>
                <h3>added blobs</h3>
                <div className='blobs'>

                </div>
            </div>
            
            <form>
                <h1>Create A New Collection</h1>
                <label>Name</label>
                <input placeholder='Enter Your Item Name Here' />
                <label>Description</label>
                <input className='description' />
                <label>Custom Field 1</label>
                <input placeholder='Enter Custom Field 1 Here' />
                <label>Custom Field 2</label>
                <input placeholder='Enter Custom Field 2 Here' />
                <label>Tags</label>
                <input placeholder='Enter Tags Here' value={currentTag} onChange={(e) => setCurrentTag(e.target.value)} />
                <button onClick={(e) => handleAddTag(e)}>Add Tag</button>
                <label>Add an Item</label>
                <select>
                    {blobs.map((blob, index) => {
                        return (
                            <option key={index} value={blob.name}>{blob.name}</option>
                        )
                    })}
                </select>
                <button>Create</button>
            </form>
            <div className='right'>
                <h3>added tags</h3>
                <div>
                    {tags.map((tag, index) => {
                        return (
                            <div className='tag' key={index}>
                                {tag}
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default styled(Collection)`
    background: var(--primary) center top / cover no-repeat fixed;
    width: 70%;
    height: 80%;
    margin: auto;
    background-color: red;
    border-radius: 1rem;
    background-color: var(--tertiary);
    filter: drop-shadow(10px 10px 10px rgba(0, 0, 0, 0.25));
    display: flex;
    flex-direction: row;

    .left {
        width: 50%;
        height: 80%;
        background-color: gray;
        border-radius: 1rem;
        text-align: center;
        display: flex;
        flex-direction: column;
        margin: auto;
        margin-right: 1rem;
        margin-left: 1rem;

        & h3 {
            background-color: white;
            width: 70%;
            text-align: center;
            margin: 1vh auto;
            border-radius: 1rem;
        }

        & .tag {
            background-color: white;
            width: 70%;
            text-align: center;
            margin: 1vh auto;
            border-radius: 1rem;
        }

        & .blobs {
            background-color: gray;
            width: 100%;
            height: 100%;
            border-radius: 1rem;
            
        }
        
    }

    .right {
        width: 50%;
        height: 80%;
        background-color: gray;
        border-radius: 1rem;
        text-align: center;
        display: flex;
        flex-direction: column;
        margin: auto;
        margin-right: 1rem;
        margin-left: 1rem;

        & h3 {
            background-color: white;
            width: 70%;
            text-align: center;
            margin: 1vh auto;
            border-radius: 1rem;
        }

        & .tag {
            background-color: white;
            width: 70%;
            text-align: center;
            margin: 1vh auto;
            border-radius: 1rem;
        }
    }

    form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        height: 80%;

        & label {
            margin-top: .5rem;
        }

        & input {
            border: none;
            border-radius: 1rem;
            margin: .5rem;
        }

        & .description {
            height: 10vh;
        }

        & button {
            width: 10vw;
            border: none;
            background-color: var(--primary);
            color: white;
            border-radius: 1rem;
            margin-top: 1rem;
            filter: drop-shadow(10px 10px 10px rgba(0, 0, 0, 0.25));
        }

        & select {
            border: none;
            padding: .5rem;
            border-radius: 1rem;
            margin: .5rem;
        }
    }


`