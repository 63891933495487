import styled from 'styled-components';
import { HandlePostFetch } from '../../handlers/HandlePostFetch';
import Post from './Post';
import Comments from './Comments';

const PostDisplay: React.FC<X> = ({ className, user }) => {
	// fetches posts at page load
	const { posts } = HandlePostFetch();

	return (
		<list className={className}>
			{posts?.map((post, i) => {
				return (
					<post>
						<Post
							key={i}
							post={post}
							user={user}
						/>
						<Comments post={post} />
					</post>
				);
			})}
		</list>
	);
};

export default styled(PostDisplay)`
	overflow: auto;
	padding: 0rem 2rem;

	& post {
		display: block;
		background: #cfcfcf;
		border-radius: 1rem;
		margin-bottom: 2rem;
	}

	@media screen and (max-width: 480px) {
		padding: 0;
	}
`;
