// ----------------------
// this file is used to set module wide styling / state values (surrounds router)
// update page content in QRLRoutes
// update global components inside of QRLShell/components
// otherwise tread lightly
// - TT
// ----------------------

import styled from 'styled-components';
import Filter from './components/Filter';
import LeftSideBar from '../../II-DASHBOARD/components/LeftSideBar';
import { FaArrowAltCircleDown } from 'react-icons/fa';
import SearchIcon from '../../../assets/png/Q-01.png';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const ModuleShell: React.FC<X> = ({ className, children }) => {
	const [buildingSelected, setBuildingSelected] = useState("");
	const [machineSelected, setMachineSelected] = useState("");

	const navigate = useNavigate();

	const activeNavOptions = [
		"Laser Table",
		"WallE"
	];

	const activeMachines = [
		"Pen",
		"Vacuum",
		"Bladder",
		"Laser Head",
		"ClearWeld",
	]

	const toLowerCaseNoSpaces = (str: string) => {
		let newStr = str.split(" ").join("").toLowerCase();
		return newStr;
	}

	return (
		<module
			id="QRL"
			className={className}
		>
			{/* <SearchBar /> */}
			{/* <Filter /> */}
			
			<content>
				<LeftSideBar>
					<div className="QRL-list">
						<button className="QRL-drop">
							<img className="search" src={SearchIcon} /><input placeholder='Search for a procedure' />
						</button>
						<h2>Technology</h2>
						
						{activeNavOptions.map((option, i) => {
							return (
								<button onClick={() => {
									setBuildingSelected(option);
									navigate(`/QRL/${toLowerCaseNoSpaces(option)}`);
								}} className={option == buildingSelected ? "supercollection selected" : "supercollection"} key={i}>
									{option}
								</button>
							);
						  })}

					</div>
				</LeftSideBar>
				{children(buildingSelected, machineSelected)}
			</content>
		</module>
	);
};

export default styled(ModuleShell)`

	background-color: var(--primary);
	width: 100%;
	height: 100%;

	& content {
		display: flex;
		flex-direction: row;
		height: 100%;
	}

	& .QRL-list {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	& .supercollection {
		background-color: var(--tertiary);
		border: none;
		margin: .5rem;
		border-radius: 1rem;
		filter: drop-shadow(5px 5px 4px rgba(0, 0, 0, 0.5));
		
	}

	& .selected {
		background-color: var(--secondary);
	}

	& .QRL-drop {
		border: none;
		margin: .5rem;
		border-radius: 1rem;
		filter: drop-shadow(5px 5px 4px rgba(0, 0, 0, 0.5));
		background-color: white;
		color: var(--primary);
		font-weight: 500;
		align-content: center;
		display: flex;
		/* justify-content: center; */
		align-items: center;

		& img {
			width: 1.5rem;
		}
		& input {
			border: none;
			border-radius: 0 1rem 1rem 0;
			height: 1.5rem;
			margin: .5rem 0;
		}
	}

	& button:hover {
		transform: scale(1.01);
	}

	@media screen and (max-width: 480px) {
		& content {
			/* top: 18vh; */
			position: relative;
			display: block;
		}
	}
`;
