import { FC } from 'react';
import styled from 'styled-components';
import Header from '../../../../GlobalShell/Header'
import LeftSideBar from '../../../II-DASHBOARD/components/LeftSideBar';

interface props {
    className?: string;
}

const AdminManageUsers: FC <props> = ({ className }) => {

    const users = [
        {
            firstName: "firstTest1",
            lastName: "lastTest1",
            email: "test@testing.com",
            approved: true,
        },
        {
            firstName: "firstTest2",
            lastName: "lastTest2",
            email: "test@testing.com",
            approved: true,
        },
        {
            firstName: "firstTest3",
            lastName: "lastTest3",
            email: "test@testing.com",
            approved: false,
        },
        {
            firstName: "firstTest4",
            lastName: "lastTest4",
            email: "test@testing.com",
            approved: true,
        },
    ]

    return (
        <admin className={className}>
            <section>
                <div className="filter">
                    <h5>filter by: First Name, Last Name, Username, Email</h5>
                </div>
                <div className="user-table">
                    <table>
                        <tr>
                            <td>First Name</td>
                            <td>Last Name</td>
                            <td>Email</td>
                            <td>Actions</td>
                        </tr>
                        {users.map((user, index) => {
                            return (
                                <tr key={index}>
                                    <td>{user.firstName}</td>
                                    <td>{user.lastName}</td>
                                    <td>{user.email}</td>
                                    {user.approved ? <td><button>Edit</button></td>
                                    : <td><button className="approve-btn">Approve</button></td>
                                    }
                                </tr>
                            )
                        })}
                    </table>
                </div>
                <div className="user-container">
                    {users.map((user, index) => {
                        return (
                            <div className="single-user" key={index}>
                                <h3>{user.firstName}</h3>
                            </div>
                        )
                    })}
                </div>
            </section>
        </admin>
    )
}

export default styled(AdminManageUsers)`
    height: 100vh;
    background-color: var(--primary);
    /* width: 100%; */
    display: flex;
	flex-direction: row;
    justify-content: space-between;
    margin-left: 5vh;

    & section {
        display: flex;
        flex-direction: column;

        & .filter {
            justify-content: center;
            margin-top: 8vh;
        }
    }

    .user-table {
        margin-top: 1vh;
        display: flex;
        justify-content: flex-end;
        

        & table {
            background-color: white;
            width: 75vw;
            max-height: 80vh;
            align-items: center;
            min-height: 40vh;
            text-align: center;
            border-radius: 1rem;

            & td {
                padding: .5rem;
                font-weight: 500;
            }

            & tr:nth-child(odd) {
                background-color: #9e9e9e;
            }

            & button {
                border: none;
                border-radius: 1rem;
                margin: .5rem;
                padding: .5rem;
                width: 12rem;
                background-color: #383838;
                color: white;
                font-weight: 600;
            }

            & .approve-btn {
                color: black;
                background-color: #9e9e9e;
                border: red solid 2px;
            }
        }
    }

    @media screen and (max-width: 480px) {
        margin-left: 0;

        .user-table {
            display: none;
        }

        .user-container {
            width: 95%;
            background-color: #383838;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin:auto;
            border-radius: 1rem;
        }
    }
`