import styled from 'styled-components';
import { FunctionComponent, useState } from 'react'
import WSULogo from '../../../assets/wsu_text.png';
import logo from '../../../assets/ISAIC_Logo_Red.png';
import ARMLogo from '../../../assets/ARMLOGO.png';
import KennonLogo from '../../../assets/kennon/kennon_logo_white.png'
import NewsIcon from "../../../assets/png/News_Icon.png"
import QRLIcon from "../../../assets/png/QRL_Icon-01.png"
import NotesIcon from "../../../assets/png/Notes_Icon-01.png"
import ToolsIcon from "../../../assets/png/Tools_Icon-01.png"
import SettingsIcon from "../../../assets/png/User_manager.png"
import { Link } from 'react-router-dom';
import profileImage from "../../../assets/profile.png"


type NavOption = {
	src: string,
	name: string,
	link: string,
}

type LeftSideBarProps = {
	collapsed: boolean,
}

const LeftSideBar = styled.div<LeftSideBarProps>`
  position: relative;
  background: var(--primary-light);
  min-width: ${props => props.collapsed ? 'var(--navbar-width-collapsed-min)' : 'var(--navbar-min-width)'};
  max-width: ${props => props.collapsed ? 'var(--navbar-width-collapsed)' : 'var(--navbar-width)'};
  text-align: center;
  padding: 1rem;
  color: #fff;
  /* font-style: italic; */
  overflow: auto;
  filter: drop-shadow(5px 5px 4px rgba(0, 0, 0, 0.5));
  display: flex;
`;

const LeftSideBarComp: React.FC<X> = ({ className, children }) => {
  const [selected, setSelected] = useState("");
  const [collapsed, setCollapsed] = useState(false);

  const activeNavOptions: NavOption[] = [
    {
      src: QRLIcon,
      name: "QRL",
      link: "/qrl",
    },
    {
      src: ToolsIcon,
      name: "Tools",
      link: "/dashboard"
    },
  ];

  const handleCollapse = () => {
    setCollapsed(!collapsed);
    localStorage.setItem('nav', collapsed ? 'true' : 'false');
  };

  return (
    <LeftSideBar collapsed={collapsed} className={`user-feed ${className}`}>
      <div className="content">
        <Link to="/dashboard">
          <img className="logo" style={{ display: `${collapsed ? `none` : `block`}`}} src={logo} />
        </Link>
		<img style={{ width: '40%', display: `${collapsed ? `none` : `block`}`}} src={KennonLogo} />
        <img className={!collapsed ? 'profile' : 'profile-collapse'} src={profileImage} />
        <div className="hello" style={{ display: `${collapsed ? `none` : `block`}`}}>
          <h2>Hello,</h2>
          <h2>Cassandra Franklin</h2>
        </div>
        <div className="quicknav-title" style={{ display: `${collapsed ? `none` : `block`}`}}>
          <h3>QRL QuickNav</h3>
        </div>
        <div className={collapsed ? `app-list-collapsed` : `app-list`}>
          {children}
        </div>
      </div>
      <div className="controller" onClick={handleCollapse}>
        <div className="line"></div>
        <div className="line"></div>
      </div>
    </LeftSideBar>
  );
};

export default styled(LeftSideBarComp)`
	/* position: relative;
	background: var(--primary-light);
	min-width: var(--navbar-width);
	max-width: var(--navbar-width);
	text-align: center;
	padding: 1rem;
	color: #fff;
	/* font-style: italic; */
	/* overflow: auto;
	filter: drop-shadow(5px 5px 4px rgba(0, 0, 0, 0.5));
	display: flex; */


	& .controller {
		display: flex;
		flex-direction: row;
		margin-left: 1rem;
		cursor: pointer;
		& .line {
			width: .2rem;
			margin-right: .2rem;
			top: 0;
			background-color: white;
			pointer-events: none;
		}

	}

	& .hello {
		background-color: var(--primary);
		border-radius: 1rem;
		color: black;
		filter: drop-shadow(5px 5px 4px rgba(0, 0, 0, 0.5));
	}

	& .logo {
		width: 100%;
		margin: auto;
	}

	& .profile {
		border-radius: 50%;
		filter: drop-shadow(5px 5px 4px rgba(0, 0, 0, 0.5));
		width: 10rem;
		height: 10rem;
		margin-bottom: 1rem;
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
	}

	& .profile-collapse {
		border-radius: 50%;
		filter: drop-shadow(5px 5px 4px rgba(0, 0, 0, 0.5));
		width: 5rem;
		height: 5rem;
		margin-bottom: 1rem;
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
	}

	& .quicknav-title {
		border-radius: 1rem;
		color: #1a1a1a;
		margin-top: 1rem;
		font-weight: 600;
		border-bottom: 2px solid #1a1a1a;
	}

	& .profile:hover {
		cursor: pointer;
		transform: scale(1.01);
	}

	& .logo:hover {
		cursor: pointer;
		transform: scale(1.01);
	}

	& h2 {
		margin: 0;
		text-decoration: none;
		font-weight: 600;
		font-style: none;
		font-size: x-large;
	}


	& .app-list {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		margin-top: 2vh;
	}



	& .app-list-collapsed {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		margin-top: 2vh;
		/* display: none; */

		& p {
			display: none;
		}

		& h2 { 
			display: none;
		}

		& h4 {
			display: none;
		}

		& .QRL-drop {
			display: none;
		}

		& svg {
			display: none;
		}


		& .QRL-list {
			& .module-header {
				display: none;
			}
		}


		& .app-list {
			display: flex;
			& .app {
				padding: .2rem;
				margin-top: .5rem;
			}
			& img {
				display: flex;
				size: nav(--navbar-width-collapsed-min);
				margin: 0;
				padding: 0;
			}
		}
	}

	& .app-button {
		display: block;
		/* width: 50%; */
		border-radius: 1rem;
		margin: auto;
		border: none;
		font-weight: 500;
		/* padding: .3rem; */
		filter: drop-shadow(10px 10px 10px rgba(0, 0, 0, 0.5));
		white-space: whitespace;

		& img {
			width: 100%;
		}

		&:hover {
			transform: scale(1.1);
			cursor: pointer;
		}
	}
	
	& img {
		width: 100%;
		bottom: 2em;
		justify-content: center;
		align-items: center;
		margin: auto;
	}

	@media screen and (max-width: 1086px) {
		min-width: 30vw;
		max-width: 30vw;
		
	}

	@media screen and (max-width: 756px) {
		display: none !important;
	}
`;
