import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
// --------------------------
//---------------------------
// import { LoginProvider } from './config/context';
import GlobalShell from './GlobalShell';
import GlobalRouter from './GlobalRouter';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
	<BrowserRouter>
		<GlobalShell>
			<GlobalRouter />
		</GlobalShell>
	</BrowserRouter>
);
