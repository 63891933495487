import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import styled from 'styled-components';

import CreateBlob from './Blob/page'
import CreateCollection from './Collection/page'

const CreateRouter: FC<X> = ({ className }) => {
    return (
        <Routes>
            <Route
                path="/blob"
                element={<CreateBlob />}
            />
            <Route
                path="/collection"
                element={<CreateCollection />}
            />
        </Routes>
    )
}

export default CreateRouter;