import { FC, useEffect } from 'react';
import styled from 'styled-components';
import Header from '../../GlobalShell/Header'

interface props {
	className?: string;
}

const ProfilePage: FC<props> = ({ className }) => {
	return (
		<section className={`Profile ${className}`}>
			<Header />
			<img src={require('../../assets/profile.png')} />
		</section>
	);
};

export default styled(ProfilePage)`
	background: var(--primary);
	width: 100%;
	height: 100vh;
	text-align: center;
	padding: 1rem;

	& img {
		border-radius: 50%;
		width: 30vh;
	}
`;
