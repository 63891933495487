
import NewsRouter from './NewsRoutes';
import NewsShell from './NewsShell';

const News = () => {
    return (
        <NewsShell>
            <NewsRouter />
        </NewsShell>
    )
}

export { News }
