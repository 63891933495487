//packages
import styled from 'styled-components';

// footer content and logic
const Footer: React.FC<X> = ({ className }) => {
	const link = 'https://www.interactivetrainingsystems.com';
	return (
		<footer className={className}>
			<div className="footer-left">
				<p className="powered-by">Powered By:</p>
				<a href={link}>
					<img
						className="footer-logo"
						src={require('./assets/footer-logo.png')}
						alt="logo"
					/>
				</a>
			</div>
			<div className="footer-right"></div>
		</footer>
	);
};

// footer styling
export default styled(Footer)`
	/* background: #555555; */
	width: 100%; // width of entire page
	height: var(--footer-height);
	min-height: 48px;
	position: fixed; // stick in place
	bottom: 0; // stick to bottom of page
	display: flex; // organized left-right
	justify-content: space-between; // footer-left and footer-right placed on opposite sides
	z-index: 100; // layer footer above anything else
	opacity: 40%;

	// left section
	.footer-left {
		height: 100%;
		display: flex;
		margin: auto 1rem;

		// powered by text
		.powered-by {
			margin: auto 0;
			padding: 5px;
			font-weight: bold;
			font-size: 18px;
			color: #fff;
		}

		// lower-left logo
		.footer-logo {
			margin: 5px;
			height: 100%;
		}

		& a {
			height: 80%;
		}
	}

	// right section
	.footer-right {
		display: flex;
		margin: 0 0.5rem;

		// footer right buttons
		& button {
			margin: auto 0.4vw;
			height: 90%;
			width: 3.5vw;

			// footer right buttons hover
			&:hover {
				transform: scale(1.05);
				filter: opacity(0.8);
			}
		}
	}

	@media screen and (max-width: 480px) {
		z-index: 1;

		.footer-left {
			padding: 10px;
			margin: 0;
		}

		.footer-right {
			margin: 0;
			text-align: center;
			
			& h2 {
				font-size: 18px;
				margin: auto;
			}
		}


		@media screen and (max-width: 480px) {
			margin: auto;
			width: 100%;
			margin-bottom: 1vh;

			.footer-left {
				display: flex;
				margin: auto;
			}
		}
	}
`;
